import { CheckoutStage } from "@n3oltd/karakoram.checkout.sdk.checkout";
import { DonationKeys } from "../types";

export const getNextStage = (remainingStages: CheckoutStage[] | undefined, callback?: (stage: DonationKeys) => void): DonationKeys | undefined => {
  if (!remainingStages?.length) {
    return undefined;
  }

  const stages = remainingStages.filter(s => s !== CheckoutStage.Account);
  const nextStage = stages[0];
  if (nextStage && callback) {
    callback(nextStage as DonationKeys);
  }

  return nextStage as DonationKeys;
};
