import { useState, useEffect } from 'react';
import Cookie from 'js-cookie';
import { AccountType } from '@n3oltd/karakoram.checkout.sdk.checkout';
import { CartRes } from '@n3oltd/karakoram.cart.sdk.cart';

import { getNextStep, getPreviousStep } from './checkout/navigation';
import { IntlProvider } from './i18n';
import StepRenderer from './checkout/components/StepRenderer';
import { Drawer } from './checkout/components/Drawer';
import { LoadingOverlay } from './Loader';
import { useEnvironmentContext } from '@/api/common/contexts/EnvironmentProvider';
import { useSheetStyles } from './hooks/useSheetStyles';
import { useCheckoutSession } from './checkout/hooks/useCheckoutSession';
import { useApi } from './api/common/hooks/useApi';
import AppManager from '@/api/common/clients/AppManager';
import {K2RestService} from '@/api/common/clients/K2RestService';
import { loadElementsFile, loadLookupsFile } from '@/api/checkoutProfile/loadProfile';
import { profileConfig } from '@/api/common/config/ProfileConfig';
import { LookupService } from './api/common/utils/Lookup';
import { _cartClient, _checkoutClient } from './api/common/clients/K2RestClient';
import { CART_COOKIE_KEY } from './api/common/constants/cookie';
import { getAttributionCookie } from './utils/cookie/cookie';
import { CheckoutProfileConfig, CheckoutState, Lookups, StepScreens } from './checkout/types';

import { applyTheme,Theme } from './utils/theme';

import theme from './theme.json'

import '@n3oltd/n3o-ui-components/dist/index.css'
import './index.css'

applyTheme(theme as any as Theme)

const initialState: CheckoutState = {
  donationType: "oneOff",
  paymentMethod: null,
  selectedPaymentMethod: null,
  currentStep: StepScreens.Basket,
  currentDonationFlow: "donation",
  availablePaymentMethods: [],
  paymentPaid: {
    oneOff: false,
    regular: false,
    scheduled: false,
  },
  personalInfo: {
    email: "",
    phoneCode: "+44",
    phone: "",
    title: "Mr",
    firstName: "",
    lastName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    area: "",
    country: "",
    postCode: "",
    terms: false,
  },
  checkoutProfile: null,
  checkoutSession: {},
  checkoutInfo: { accountType: AccountType.Individual },
  cart: null,
  legacy: false,
  checkoutProfileId: ''
};

export type AppProps = {
  subscriptionId: string;
  checkoutProfileId: string;
  open: boolean;
  legacy?: boolean;
  onClose?: () => void;
  onError?: (error: any) => void;
  hasPayitHash?: boolean
}

export default function App({subscriptionId, checkoutProfileId, open, legacy, onClose, onError, hasPayitHash}: AppProps) {
  const [state, setState] = useState<CheckoutState>(() => {
    initialState.legacy = legacy || false
    initialState.hasPayitHash = hasPayitHash;
    initialState.onError = onError; 
    initialState.checkoutProfileId = checkoutProfileId;

    return initialState;
  });
  const [isSheetOpen, setIsSheetOpen] = useState(open);
  const [isLoading, setIsLoading] = useState(true);
  
  const { execute, isLoading: isCheckoutSessionLoading } = useCheckoutSession({ updateState, hasPayitHash });
  const {execute: getCart, isLoading: loadingCart} = useApi<CartRes>({
    onSuccess(data) {
      updateState({cart: data})
    }
  })

  useSheetStyles(open, setIsSheetOpen);

  const env = useEnvironmentContext();
  const { loaded } = env;

  K2RestService.setSubscriptionId(subscriptionId);


  useEffect(() => {
    if (!isSheetOpen && open) {
      onClose?.();
    }
  }, [isSheetOpen, onClose, open]);

  useEffect(() => {
    if (legacy) {
      updateState({currentStep: StepScreens.DonationDetails})
    }
  }, [legacy])


  useEffect(() => {
    K2RestService.setErrorHandler(onError);
    async function loadSession() {
      if (_checkoutClient) {
        try {
          if (Cookie.get(CART_COOKIE_KEY)) {
            await execute(_checkoutClient.beginOrResume(Cookie.get(CART_COOKIE_KEY)!, {
              attribution: getAttributionCookie(),
              checkoutProfileId: checkoutProfileId
            }));
            
          }
        } catch(error) {
          onError?.(error)
        }
      }
    }

    async function loadCart(){
      if (_cartClient) {
        try {
          if (Cookie.get(CART_COOKIE_KEY)) {
            await getCart(_cartClient.getById(Cookie.get(CART_COOKIE_KEY)!));
          }
        } catch(error) {
          onError?.(error)
        }
      }
    }
    
    if (loaded && open) {
      AppManager.initialize(env);
      const url = profileConfig[env.environment].host;
      Promise.all([
          loadElementsFile(url, subscriptionId, checkoutProfileId),
          loadLookupsFile(url, subscriptionId),
          !legacy && loadCart(),
          loadSession()
        ])
        .then(([elements, lookups]) => {
          LookupService.setLookups(lookups as Lookups);
          updateState({
            checkoutProfile: elements as CheckoutProfileConfig
          })
      }).catch((e) => {
        onError?.(e)
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }, [loaded, env, subscriptionId, checkoutProfileId, execute, getCart, open, hasPayitHash, legacy]);

  function updateState (newState: Partial<CheckoutState>) {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  const goToNextStep = () => {
    setState(prevState => {
      const nextStep = getNextStep(prevState) as CheckoutState['currentStep'];
      return nextStep ? { ...prevState, currentStep: nextStep } : prevState;
    });
    setIsSheetOpen(true);
  };

  const goToPreviousStep = () => {
    setState(prevState => {
      const previousStep = getPreviousStep(prevState) as CheckoutState['currentStep'];
      return previousStep ? { ...prevState, currentStep: previousStep } : prevState;
    });
    setIsSheetOpen(true);
  };

  if (!Cookie.get(CART_COOKIE_KEY)) {
    return null;
  }

  const bgClass = state.currentStep === StepScreens.Basket ? "bg-card text-card-foreground" : ""
  
  if (isLoading || isCheckoutSessionLoading || loadingCart) {
    
      return (
        <Drawer
          open={isSheetOpen}
          close={() => setIsSheetOpen(false)}
          customClass={bgClass}
        >
         <LoadingOverlay isLoading={true} /> 
        </Drawer>
      );
  }

  return (
    <IntlProvider>
        <StepRenderer
          state={state}
          updateState={updateState}
          goToNextStep={goToNextStep}
          goToPreviousStep={goToPreviousStep}
          isSheetOpen={isSheetOpen}
          setIsSheetOpen={setIsSheetOpen}
        />
    </IntlProvider>
  );
}