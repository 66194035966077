import { PublicClient } from "@n3oltd/k2.accounts.sdk.public";
import { CheckoutClient, IConfig } from "@n3oltd/karakoram.checkout.sdk.checkout";
import { PayitClient } from "@n3oltd/karakoram.checkout.sdk.payit";
import { ElementsClient } from "@n3oltd/karakoram.elements.sdk.elements";
import { LoginClient } from "@n3oltd/karakoram.elements.sdk.login";
import { DirectDebitUKClient } from '@n3oltd/karakoram.checkout.sdk.direct-debit-uk';
import { NetworkMerchantsClient } from '@n3oltd/karakoram.checkout.sdk.network-merchants';
import { CartClient } from '@n3oltd/karakoram.cart.sdk.cart';

import K2RestService from "./K2RestService";
import AppManager from "./AppManager";
import { IK2RestClients, IBaseUrlKeys, RestClient } from "./types";

export let _accountPublicClient: PublicClient | null = null;
export let _checkoutClient: CheckoutClient | null = null;
export let _cartClient: CartClient | null = null;
export let _directDebitUKClient: DirectDebitUKClient | null = null;
export let _elementsClient: ElementsClient | null = null;
export let _loginClient: LoginClient | null = null;
export let _networkMerchantsClient: NetworkMerchantsClient | null = null;
export let _payitFlowsPaymentsClient: PayitClient | null = null;

class K2RestClients implements IK2RestClients {
  initialize() {
    _accountPublicClient = this.initRestClient<PublicClient>(PublicClient, 'accounts')
    _checkoutClient = this.initRestClient<CheckoutClient>(CheckoutClient, 'checkout')
    _cartClient = this.initRestClient<CartClient>(CartClient, 'cart')
    _directDebitUKClient = this.initRestClient<DirectDebitUKClient>(DirectDebitUKClient, 'checkout')
    _elementsClient = this.initRestClient<ElementsClient>(ElementsClient, 'elements')
    _loginClient = this.initRestClient<LoginClient>(LoginClient, 'elements')
    _networkMerchantsClient = this.initRestClient<NetworkMerchantsClient>(NetworkMerchantsClient, 'checkout')
    _payitFlowsPaymentsClient = this.initRestClient<PayitClient>(PayitClient, 'checkout')
  }

  initRestClient<T>(
    clientAPIType: any,
    apiUrlKey: IBaseUrlKeys,
    customConfiguration?: Partial<IConfig>,
  ): T {

    return this.getRestClient(
      clientAPIType,
      this.baseUrlFor(apiUrlKey),
      customConfiguration,
    );
  }

  getRestClient = <T>(
    RestClientNew: RestClient<T>,
    clientBaseUrl: string,
    customConfiguration?: Partial<IConfig>,
  ): T => {

    return new RestClientNew(
      { ...K2RestService.defaultClientConfiguration, ...customConfiguration },
      clientBaseUrl,
    );
  };

  baseUrlFor(key: IBaseUrlKeys) {
    if (!AppManager.baseUrls)
      throw new Error("baseUrls haven't been initialized yet");

    const baseUrl: string = AppManager.baseUrls[key];

    return baseUrl;
  }
}

export default new K2RestClients();