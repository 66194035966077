import { ConsentOption, Country, Lookups, OrgType } from "@/checkout/types";

export class LookupService {
  private static lookups: Lookups;

  static setLookups(lookups: Lookups) {
    this.lookups = lookups;
  }

  static getLookups() {
    return this.lookups;
  }

  static getAccountTitles() {
    return this.lookups["accounts.title"];
  }

  static getCommunicationChannels(chanels: ConsentOption[]):  {
    id: string;
    label: string;
}[] {
    const consentOptions = chanels.map(option => {
        return {
          id: option.channel,
          label: this.lookups["communications.channel"].find(lookup => lookup.Id === option.channel)?.Name || '',
        };
      }) || [];

      return consentOptions;
  }

  static getOrganisationTypes(): OrgType[] {
    return this.lookups['accounts.organisationsType'] || [];
  }

  static getCountry(iso2Code: string): Country | undefined {
    return this.lookups["common.country"].find(c => c.Iso2Code === iso2Code);
  }

}