import { useCallback } from "react";
import { AccountMatchCriteria, AccountRes } from "@n3oltd/k2.accounts.sdk.public";

import { _accountPublicClient } from "@api/common/clients/K2RestClient";
import { IApiResponse } from "@api/common/clients/ApiResponseModel";
import K2RestService from "@api/common/clients/K2RestService";

export const useGetAccounts = (): {
  fetchAccoucts: (req: AccountMatchCriteria) => Promise<IApiResponse<AccountRes[]> | null>;
} => {

  const fetchAccoucts = useCallback(async (req: AccountMatchCriteria ) => {
    const res: IApiResponse<AccountRes[]> = await K2RestService.toResponse(_accountPublicClient!.findMatchesByCriteria(req));

    return res;
  }, []);

  return {
    fetchAccoucts
  }
}