import { CartRes, TransactionType } from "@n3oltd/karakoram.cart.sdk.cart";
import { CheckoutRes } from "@n3oltd/karakoram.checkout.sdk.checkout";
import { Button, DonationSection, DonationSummary } from "@n3oltd/n3o-ui-components"

import { Drawer } from "./Drawer"
import { useTranslation } from "@/i18n";
import { LoadingOverlay } from "@/Loader";
import { useApi } from "@/api/common/hooks/useApi";
import { _cartClient, _checkoutClient } from "@/api/common/clients/K2RestClient";
import { ServerError } from "@/common/components/Error";

import { CheckoutState } from "../types";
import { getAttributionCookie } from "@/utils/cookie/cookie";
type Props = {
  state: CheckoutState;
  updateState: (newState: Partial<CheckoutState>) => void;
  goToNextStep: () => void;
}

export function Basket({state, goToNextStep, updateState }: Props) {

  const { formatMessage, formatCurrency } = useTranslation();
  const {execute, isLoading, error} = useApi<string>({
    onSuccess() {
      getCart(_cartClient!.getById(state.cart?.id!))
    },
  })
  
  const {execute: getCart, isLoading: loadingCart, error: cartFetchError} = useApi<CartRes>({
    onSuccess(data: CartRes) {
      updateState({cart: data})
      getSession(_checkoutClient!.beginOrResume(data.id!, {
        attribution: getAttributionCookie(),
        checkoutProfileId: state.checkoutProfileId
      }))
    },
  })
  
  const {execute: getSession, isLoading: sessionLoading, error: sessionError} = useApi<CheckoutRes>({
    onSuccess(data) {
      updateState({checkoutSession: data})
    },
  })

  const hasDonation = !!state.cart?.donation?.items?.length;
  const hasRegularGiving = !!state.cart?.regularGiving?.items?.length;

  const renderDonations = () => {
    return state.cart?.donation?.items?.map((item, index) => {
      const description = (() => {
        return Object.values(item.fundDimensions || {}).join(' - ');
      })()
      
      return (
        <DonationSection.Content
            key={index}
            amount={formatCurrency(item.value?.amount!, item.value?.currency!)}
            description={description}
            name={item.summary || ''}
          >
            <DonationSection.Footer onRemove={() => handleCartItemRemove(index, TransactionType.Donation)} />
          </DonationSection.Content>
      )
    })
  }
  
  const renderRegularGiving = () => {
    return state.cart?.regularGiving?.items?.map((item, index) => {
      const description = (() => {
        return Object.values(item.fundDimensions || {}).join(' - ');
      })()
      return (
        <DonationSection.Content
            key={index}
            amount={formatCurrency(item.value?.amount!, item.value?.currency!)}
            description={description}
            name={item.summary || ''}
          >
            <DonationSection.Footer onRemove={() => handleCartItemRemove(index, TransactionType.RegularGiving)} />
          </DonationSection.Content>
      )
    })
  }

  const calculatedTotal = (() => {
    const donationsTotal = formatCurrency(state.cart?.donation?.total?.amount || 0, state.cart?.currency?.code!);
    const regularGivingTotal = formatCurrency(state.cart?.regularGiving?.total?.amount || 0, state.cart?.currency?.code!);;

    if (hasDonation && hasRegularGiving) {
      return {total: `${donationsTotal} + ${regularGivingTotal}`, frequency: `/${formatMessage('common.month')}`}
    }

    if (hasDonation) {
      return {total: `${donationsTotal}`, frequency: ``}
    }

    if (hasRegularGiving) {
      return {total: `${regularGivingTotal}`, frequency: `/${formatMessage('common.month')}`}
    }

    return {total: formatCurrency(0, state.cart?.currency?.code!),  frequency: hasRegularGiving ? `/${formatMessage('common.month')}`: ''}
  })();

  const handleCartItemRemove = (index: number, transType: TransactionType) => {
    const req = _cartClient!.remove(state.cart?.id!, {
      index: index,
      type: transType,
    })
    
    execute(req)
  }


  return <>
      <LoadingOverlay 
        isLoading={isLoading || loadingCart || sessionLoading }
      />
      <Drawer.Header>
        {formatMessage('basket.title')}
      </Drawer.Header>
      <Drawer.Content>
        {hasDonation && <DonationSection>
          <DonationSection.Header title={formatMessage('basket.oneOff.title')} />
          {renderDonations()}
        </DonationSection>}
        {hasRegularGiving &&<DonationSection>
          <DonationSection.Header title={formatMessage('basket.regular.title')} />
          {renderRegularGiving()}
        </DonationSection>}
        {error && <ServerError error={error}/>}
        {cartFetchError && <ServerError error={cartFetchError}/>}
        {sessionError && <ServerError error={sessionError}/>}
      </Drawer.Content>
      <Drawer.Footer>
        <div className="flex flex-col">
          <DonationSummary>
            <DonationSummary.Title>{formatMessage('basket.total')}</DonationSummary.Title>
            <DonationSummary.Amount value={calculatedTotal.total} additional={''} frequency={calculatedTotal.frequency} />
          </DonationSummary>  
        </div>
        <div className="pt-2.5 gap-2 flex flex-col">
          <Button size="lg" onClick={goToNextStep} disabled={state.cart?.itemCount === 0  || !!cartFetchError || !!sessionError}>
            {formatMessage('basket.button.checkout')}
          </Button>  
        </div>          
      </Drawer.Footer>
  </>
}