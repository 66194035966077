import React from "react";
import { Button } from "@n3oltd/n3o-ui-components";
import { Drawer } from "./Drawer";
import { useTranslation } from "@/i18n";
import { CheckoutState, StepScreens } from "../types";

interface PaymentConfirmationStepProps {
  state: CheckoutState;
  updateState: (newState: Partial<CheckoutState>) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  isSheetOpen: boolean;
  setIsSheetOpen: (isOpen: boolean) => void;
}

function getRandomArrayIndex(length: number): number {
  return Math.floor(Math.random() * length);
}

export default function PaymentConfirmationStep({
  setIsSheetOpen,
  updateState,
  state
}: PaymentConfirmationStepProps) {
  const { formatMessage } = useTranslation();
  const handleDone = () => {
    setIsSheetOpen(false);
    updateState({
      currentStep: StepScreens.DonationDetails,
      selectedPaymentMethod: null,
      ...({}),
    });
  }

  React.useEffect(() => {
    window.themeConfig?.onCheckoutComplete && window.themeConfig.onCheckoutComplete(state.checkoutSession)
  }, []);

  const adverts = state.checkoutProfile?.adverts || [];
  const advert =  adverts.length > 0 ? adverts[getRandomArrayIndex(adverts.length)] : null;

  return (
    <>
      <Drawer.Header />
      <Drawer.Content>
        <div className="p-6 flex flex-col items-center justify-start space-y-4 md:space-y-20 text-center">
          <div className="space-y-2">
            <div className="flex justify-center items-center h-full mb-6">
              {state.checkoutProfile?.branding.logoUrl && (
                <img
                  className="w-[230px]"
                  src={state.checkoutProfile?.branding.logoUrl}
                />
              )}
            </div>
            <h2 className="text-2xl font-bold">
              {formatMessage("thankYou.title")}
            </h2>
            <p className="text-gray-600">
              {formatMessage("thankYou.message", {
                email: state.personalInfo?.email!,
              })}
            </p>
            <div className="flex w-full items-center justify-center">
              <svg
                width="170"
                height="140"
                viewBox="0 0 170 140"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M78.9286 35.7243L88.14 47.742L91.3232 67.5169L98.2865 62.5732L105.25 73.0435L166.964 14L78.9286 35.7243Z"
                  fill="#CFD8DC"
                />
                <path
                  opacity="0.3"
                  d="M98.3571 62.3754L95.1595 64.5229L92.8929 53.5664L98.3571 62.3754Z"
                  fill="black"
                />
                <path
                  opacity="0.3"
                  d="M88.0357 47.7724L166.964 14L93.1953 52.7005L91.2231 67.5652L88.0357 47.7724Z"
                  fill="black"
                />
                <path
                  d="M166.964 14C166.684 14.2362 166.378 14.4384 166.052 14.6027L163.353 16.1496L153.294 21.6542L119.844 39.4737L93.0794 53.5364L93.2579 52.9538C96.6308 59.1615 99.6861 64.1438 101.869 67.5791C102.92 69.2264 103.753 70.5524 104.428 71.597C104.761 72.0442 105.041 72.5297 105.261 73.0435C104.867 72.646 104.515 72.2084 104.21 71.7377C103.555 70.8738 102.622 69.568 101.472 67.8804C99.1504 64.5054 95.976 59.5834 92.504 53.3958L92.2857 53.0141L92.6825 52.7931L119.368 38.5897L152.858 21.0515L163.076 15.8482L165.873 14.4621C166.21 14.249 166.578 14.0929 166.964 14Z"
                  fill="#263238"
                />
                <path
                  d="M166.964 14.0058C167.083 14.2667 149.486 22.0327 127.668 31.5246C105.85 41.0165 88.0357 48.3411 88.0357 48.0802C88.0357 47.8193 105.514 40.0532 127.331 30.5614C149.149 21.0695 166.865 13.765 166.964 14.0058Z"
                  fill="#263238"
                />
                <path
                  d="M91.2427 67.5657C90.9589 65.1133 91.0281 62.6332 91.4481 60.2002C91.5291 57.7385 91.9579 55.3003 92.7218 52.957C93.0053 55.4027 92.9361 57.876 92.5164 60.3022C92.4486 62.7719 92.0195 65.2187 91.2427 67.5657Z"
                  fill="#263238"
                />
                <path
                  d="M15.7857 115.936C15.7857 116.213 14.7869 116.589 13.416 116.787C12.0451 116.985 10.9484 116.787 10.9288 116.609C10.9093 116.431 11.9668 115.936 13.279 115.758C14.5911 115.58 15.727 115.619 15.7857 115.936Z"
                  fill="#263238"
                />
                <path
                  d="M33.3821 107.738C33.5422 107.98 31.9014 109.674 29.5403 111.214C28.1749 112.309 26.5762 112.998 24.8982 113.217C24.7781 112.909 26.6991 111.72 29.0001 110.224C30.3312 109.145 31.8104 108.305 33.3821 107.738Z"
                  fill="#263238"
                />
                <path
                  d="M46.1429 93.1364C45.8172 94.7606 45.0883 96.2718 44.0264 97.5246C42.5064 99.7483 40.8902 101.205 40.6786 101.027C41.3201 99.5407 42.1762 98.161 43.2183 96.9343C44.6998 94.7893 45.8927 92.9986 46.1429 93.1364Z"
                  fill="#263238"
                />
                <path
                  d="M50.9181 74.2617C51.6221 75.8342 51.7914 77.5612 51.4041 79.2213C51.3848 80.9187 50.8332 82.5762 49.8133 84.0008C49.7188 82.3638 49.8673 80.7226 50.2552 79.1213C50.2664 77.4836 50.4889 75.8527 50.9181 74.2617Z"
                  fill="#263238"
                />
                <path
                  d="M39.4648 61.0161C41.1614 60.6467 42.9264 60.9894 44.3917 61.9728C46.9849 63.3547 48.1418 65.6295 47.9423 65.7358C47.7429 65.8421 46.2468 64.1838 43.9529 62.9507C41.659 61.7176 39.4249 61.3562 39.4648 61.0161Z"
                  fill="#263238"
                />
                <path
                  d="M23.6786 70.6055C23.7589 68.9159 24.4638 67.3141 25.6595 66.1037C26.6047 64.6867 28.0663 63.6843 29.7403 63.3047C29.8987 63.5591 28.0961 64.7531 26.4717 66.7496C24.8473 68.7461 23.9559 70.7034 23.6786 70.6055Z"
                  fill="#263238"
                />
                <path
                  d="M26.6847 88.8694C25.4171 87.956 24.465 86.6084 23.9787 85.0394C23.2259 83.6215 22.929 81.9703 23.1352 80.3477C23.8286 81.7222 24.4163 83.157 24.8924 84.6373C25.771 86.9927 26.9131 88.6587 26.6847 88.8694Z"
                  fill="#263238"
                />
                <path
                  d="M43.7143 97.7839C42.1145 98.1761 40.4466 98.0335 38.9191 97.3739C37.2978 97.0667 35.796 96.2249 34.6071 94.957C36.175 95.2085 37.7123 95.6573 39.1897 96.2949C40.7465 96.5844 42.2659 97.0844 43.7143 97.7839Z"
                  fill="#263238"
                />
                <path
                  d="M63.1429 93.7383C61.8609 94.8055 60.2886 95.5585 58.5801 95.9232C56.9783 96.6059 55.1978 96.8899 53.4286 96.7448C54.9201 95.9702 56.5268 95.3856 58.2016 95.0081C59.7698 94.3722 61.4346 93.9443 63.1429 93.7383Z"
                  fill="#263238"
                />
                <path
                  d="M78.9107 82.1758C79.1176 82.3715 77.5041 83.9177 75.3115 85.6009C74.1014 86.758 72.6503 87.6645 71.0503 88.2627C70.8642 88.0279 72.4777 86.4817 74.6496 84.818C75.8646 83.6714 77.3147 82.7722 78.9107 82.1758Z"
                  fill="#263238"
                />
                <path
                  d="M88.5481 71.2305C88.8056 71.354 88.5481 72.5275 87.6569 73.7215C86.7657 74.9155 85.9537 75.636 85.6764 75.4507C85.3991 75.2654 85.9933 74.2361 86.7656 73.1039C87.538 71.9716 88.2906 71.107 88.5481 71.2305Z"
                  fill="#263238"
                />
              </svg>
            </div>
          </div>

          <div className="space-y-4 w-full">
            <h3 className="text-xl font-semibold">
              {formatMessage("common.thankYou")}
            </h3>
            <p className="text-gray-600">
              {formatMessage("thankYou.message2")}
            </p>
            <div className="relative aspect-video bg-gray-200 rounded-lg w-full">
              {advert && (
                <img
                  src={advert?.imageUrl}
                  alt={advert?.link}
                  onClick={() => window.open(advert?.link, "_blank")}
                  className="cursor-pointer rounded-lg"
                />
              )}
            </div>
          </div>
        </div>
      </Drawer.Content>
      <Drawer.Footer>
        <Button
          size="lg"
          variant="outline"
          className="w-full"
          onClick={handleDone}
        >
          {formatMessage("common.done")}
        </Button>
      </Drawer.Footer>
    </>
  );
}