import { initializeSentry } from '../utils/sentry/intialize';

import React from 'react';
import * as Sentry from "@sentry/react";

import EnvironmentProvider from "@/api/common/contexts/EnvironmentProvider"
import App, { AppProps } from "@/App"

export const CheckoutElementWrapper = ({subscriptionId, checkoutProfileId, open, legacy, onClose}: AppProps) => {
  const widgetRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (widgetRef.current) {
      initializeSentry(widgetRef.current);
    }

    Sentry.setContext("checkoutWidget", {
      version: "1.0.0",
      mode: "embedded",
      subscriptionId: subscriptionId,
      checkoutProfileId: checkoutProfileId,
    });
        
  }, [subscriptionId, checkoutProfileId]);

  
  const payitHash = '#n3o/elements/callback/payit'
  const url = window.location.href;
  const hasPayitHash = url.includes(payitHash);


  if(!open && !hasPayitHash){
    return null;
  }

  return (
    <div ref={widgetRef}>
      <Sentry.ErrorBoundary>
        <EnvironmentProvider environment="prod">
          <App
            subscriptionId={subscriptionId}
            open={open || hasPayitHash}
            checkoutProfileId={checkoutProfileId}
            onClose={onClose}
            legacy={legacy}
            hasPayitHash={hasPayitHash}
          />
        </EnvironmentProvider>
      </Sentry.ErrorBoundary>
    </div>
  );
}