import { CheckoutState } from '../types';

type UseOrganizationInfoProps = {
  state: CheckoutState;
  updateState: (newState: Partial<CheckoutState>) => void;
};

export const useOrganizationInfo = ({ state, updateState }: UseOrganizationInfoProps) => {
  const updateOrgInfo = (field: 'name' | 'type', value: string) => {
    if (field === 'name') {
      updateState({
        checkoutInfo: {
          ...state.checkoutInfo,
          org: {
            ...state.checkoutInfo.org,
            name: value,
          }
        }
      });
    } else {
      updateState({
        checkoutInfo: {
          ...state.checkoutInfo,
          org: {
            ...state.checkoutInfo.org,
            type: value,
          }
        }
      });
    }
  };

  return {
    updateOrgInfo
  };
};