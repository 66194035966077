import React, { useMemo } from 'react';
import { Drawer } from './Drawer';
import { Basket } from './Basket';
import { PaymentOptions } from './PaymentOptions';
import { DonationSummary } from './DonationSummary';
import { DirectDebit } from './DirectDebit';
import CreditCard from './CreditCard';
import PersonalInfo from './PersonalInfo';
import ThankYou from './ThankYou';
import { useTranslation } from '@/i18n';
import BankTransferPayment from './BankTransfer';
import { TabDetector } from '@/utils/tabs/TabDector';
import { CheckoutState, Step, StepScreens } from '../types';

interface StepRendererProps {
  state: CheckoutState;
  updateState: (newState: Partial<CheckoutState>) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  isSheetOpen: boolean;
  setIsSheetOpen: (isOpen: boolean) => void;
}

const stepComponents: Record<Step, React.ComponentType<StepRendererProps>> = {
  [StepScreens.Basket]: Basket,
  [StepScreens.DonationDetails]: PaymentOptions,
  [StepScreens.PersonalInfo]: PersonalInfo,
  [StepScreens.CreditCard]: CreditCard,
  [StepScreens.DirectDebit]: DirectDebit,
  [StepScreens.DonationSummary]: DonationSummary,
  [StepScreens.BankTransfer]: BankTransferPayment,
  [StepScreens.ThankYou]: ThankYou,
};

export default function StepRenderer(props: StepRendererProps) {
    const [otherTabOpen, setOtherTabOpen] = React.useState(false);
  const { formatMessage } = useTranslation();

  const { state, isSheetOpen, setIsSheetOpen } = props;
  const {hasPayitHash} = state;

  const stepContent = useMemo(() => {
    if (!state) {
      return <div>{formatMessage('common.loading')}</div>;
    }

    const StepComponent = stepComponents[state.currentStep];

    if (!StepComponent) {
      return <div>{formatMessage('common.error')}</div>;
    }

    return <StepComponent {...props} />;
  }, [state, props]);

  React.useEffect(() => {
      new TabDetector(hasHash => {
        if (!hasPayitHash && hasHash) {
          setOtherTabOpen(true);
        }
      }, hasPayitHash || false)
    }, [setOtherTabOpen, hasPayitHash])

  if (!state) {
    return null;
  }

  const bgClass = state.currentStep === StepScreens.Basket ? "bg-card text-card-foreground" : ""

  return (
    <Drawer
      open={isSheetOpen}
      close={() => setIsSheetOpen(false)}
      customClass={bgClass}
    >
      {otherTabOpen ? (
        <>
          <Drawer.Header />
            <div className="flex-1 flex items-center justify-center h-full">
                      {formatMessage("bankTransfer.tabOpen")}
                    </div>
        </>
      ) : (
        stepContent
      )}
    </Drawer>
  );
}

