import { PersonalInfo } from "../types";

export const hasAddress = (info: PersonalInfo): string | undefined => {
  return info.addressLine1 
        || info.addressLine2 
        || info.addressLine3 
        || info.city 
        || info.postCode 
        || info.country  
        || info.city
        || info.area
}