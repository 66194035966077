import { Input, Label, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@n3oltd/n3o-ui-components";
import { Controller } from "react-hook-form";

type Props = {
  control: any;
  formatMessage: (key: string) => string;
  inputClasses: string;
  selectClasses: string;
}

export function NameLayout1({control, formatMessage, inputClasses, selectClasses}: Props) {
  return <div className="space-y-2">
  <Label>{formatMessage('personalInfo.name')}</Label>
  <div className="space-y-[1px]">
    <div className="flex">
      <Controller
        name="title"
        control={control}
        render={({ field }) => (
          <Select onValueChange={field.onChange} value={field.value as string}>
            <SelectTrigger className={`w-[90px] rounded-b-none rounded-r-none border-r-0 ${selectClasses}`}>
              <SelectValue placeholder={formatMessage('personalInfo.title')} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Mr">{formatMessage('personalInfo.title.mr')}</SelectItem>
              <SelectItem value="Mrs">{formatMessage('personalInfo.title.mrs')}</SelectItem>
              <SelectItem value="Ms">{formatMessage('personalInfo.title.ms')}</SelectItem>
              <SelectItem value="Dr">{formatMessage('personalInfo.title.dr')}</SelectItem>
            </SelectContent>
          </Select>
        )}
      />
      <Controller
        name="firstName"
        control={control}
        render={({ field }) => (
          <Input 
            {...field}
            value={field.value as string}
            placeholder={formatMessage('personalInfo.firstName')} 
            className={`${inputClasses} rounded-b-none rounded-l-none`} 
          />
        )}
      />
    </div>
    <Controller
      name="lastName"
      control={control}
      render={({ field }) => (
        <Input 
          {...field}
          value={field.value as string}
          placeholder={formatMessage('personalInfo.lastName')} 
          className={`${inputClasses} rounded-t-none border-t-0`}
        />
      )}
    />
  </div>
</div>
}