import React from "react"
import { cn, PaymentOption } from "@n3oltd/n3o-ui-components"
import useScript from "@/checkout/hooks/useScript"
import { NMI_SCRIPTS } from "@/checkout/consntants"
import { CollectJSPaymentToken, DonationKeys, PaymentMethodConfig, PaymentType } from "@/checkout/types"

export type AGConfig = {
  currency: string;
  country: string;
  price: string | undefined;
  token: string;
  checkoutKey: string;
  isGoogleApplePayDisabled: boolean,
  onPaymentComplete: (payload: CollectJSPaymentToken) => void;
  onPaymentFailure: (payload: any) => void;
  onLoading: (loading: boolean) => void;
};

type Props = {
  methods: PaymentMethodConfig[],
  handleClick: (paymentType: PaymentType | DonationKeys) => void,
  config: AGConfig
}

export const AppleGooglePay = ({config, methods, handleClick} :Props) => {
  const {
    loadScript: loadCollectJS,
    loaded: isCollectLoaded,
  } = useScript();


  React.useEffect(() => {
    if (!config.token) return;
    
    const payload: Record<string, string | undefined> = {
      "tokenization-key": config.token,
      price: config.price,
      country: config.country,
      currency: config.currency,
    };

    loadCollectJS(NMI_SCRIPTS.COLLECT_JS, payload);
  }, [
    loadCollectJS,
    config.token
  ]);
    
  React.useEffect(() => {
    if (!isCollectLoaded) return;

    window.CollectJS.configure({
      variant: "inline",
      styleSniffer: true,
      country: config.country,
      currency: config.currency,
      price: config.price,
      callback: function (e: CollectJSPaymentToken) {
        try {
          config.onPaymentComplete(e);
        } catch (error) {
          config.onLoading(false);
          config.onPaymentFailure(error);
          console.error(error);
        }
      },
      fields: {
        googlePay: {
          selector: "#googlepaybutton",
          emailRequired: true,
          billingAddressRequired: true,
          billingAddressParameters: {
            phoneNumberRequired: true,
            format: "MIN",
          },
          buttonType: "donate",
        },
        applePay: {
          selector: "#applepaybutton",
          type: "donate",
          requiredBillingContactFields: ["postalAddress", "name"],
          contactFields:['phone', 'email']
        },
      },
    });
  }, [isCollectLoaded]);

  return <>
    {methods.map(({processorId, supportsApplePay, supportsGooglePay}) => {
      return (
        <React.Fragment key={processorId}>
          {supportsApplePay && (
            <div
              className="relative"
            >
              <div id="applepaybutton"></div>
              <div className={cn(
                config.isGoogleApplePayDisabled ? "absolute inset-0 z-10 pointer-events-auto" : ""
              )}
              onClick={() => handleClick(PaymentType.ApplePay)}></div>
            </div>
          )}
          {supportsGooglePay && (
            <div
              className="relative"
            >
              <div id="googlepaybutton"></div>
              <div className={cn(
                config.isGoogleApplePayDisabled ? "absolute inset-0 z-10 pointer-events-auto" : ""
              )} onClick={() => handleClick(PaymentType.GooglePay)}></div>
            </div>
          )}
        </React.Fragment>
      );
       })}
      {methods.length > 0 ? <><PaymentOption.Divider/>
      </> : null}
  </>
}