import { useTranslation } from '@/i18n';
import { giftAids } from '../giftaid';
import { CheckoutState } from '../types';

type UseGiftAidTextProps = {
  state: CheckoutState;
};

export const useGiftAidText = ({ state }: UseGiftAidTextProps) => {
  const { formatMessage, formatCurrency } = useTranslation();

  const giftAidScheme = giftAids.find(ga => ga.schemeId === state.checkoutProfile?.accounts?.taxRelief?.schemeId);
  const hasDonation = !!state.checkoutSession.donation?.cartItems?.length;
  const hasRegularGiving = !!state.checkoutSession.regularGiving?.cartItems?.length;

  const getGiftAidText = () => {
    const currency = state.checkoutSession.currency!;
    const singleDoantionAmount = giftAidScheme?.calculateAmount(state.checkoutSession.donation?.total?.amount!);
    const regularDoantionAmount = giftAidScheme?.calculateAmount(state.checkoutSession.regularGiving?.total?.amount!);
  
    if (hasDonation && hasRegularGiving) {
      return `${formatCurrency(singleDoantionAmount!, currency)} + ${formatCurrency(regularDoantionAmount!, currency)}/${formatMessage('common.month')}`;
    }  
    
    if (singleDoantionAmount) {
      return `${formatCurrency(singleDoantionAmount!, currency)}`;
    } 
     
    if (hasRegularGiving) {
      return `${formatCurrency(regularDoantionAmount!, currency)}/${formatMessage('common.month')}`;
    }

    return formatCurrency(0, state.checkoutSession.currency!);
  };

  return {
    getGiftAidText,
    giftAidScheme
  };
};