import React from 'react';
import { useForm } from 'react-hook-form';
import { ArrowLeft, ArrowRight, Calendar } from 'lucide-react';
import { NonePaymentFlowRes } from '@n3oltd/karakoram.checkout.sdk.direct-debit-uk';
import { CheckoutRes, RegularGivingFrequency } from '@n3oltd/karakoram.checkout.sdk.checkout';
import { Label, Input, Button, Checkbox, Select, SelectTrigger, SelectValue, SelectContent, SelectItem, cn, Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@n3oltd/n3o-ui-components';

import { Drawer } from './Drawer';
import { ServerError } from '@/common/components/Error';
import { LoadingOverlay } from '@/Loader';
import { useTranslation } from '@/i18n';
import { useApi } from '@/api/common/hooks/useApi';
import { _checkoutClient, _directDebitUKClient } from '@/api/common/clients/K2RestClient';
import { CHECKOUT_SCOPES, PAYMENT_PROCESSORS } from '../consntants';
import { getNextStage } from "../utils/stageProgress";
import { CheckoutState, StepScreens } from '../types';
import { PaymentError } from './payments/PaymentError';

interface DirectDebitProps {
  state: CheckoutState;
  updateState: (newState: Partial<CheckoutState>) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  isSheetOpen: boolean;
  setIsSheetOpen: (isOpen: boolean) => void;
}

export  function DirectDebit({
  goToNextStep,
  goToPreviousStep,
  updateState,
  state
}: DirectDebitProps) {
  const { formatMessage } = useTranslation();
  const [showGuarantee, setShowGuarantee] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [paymentError, setPaymentError] = React.useState<{
      error: any;
      show: boolean;
    }>({ error: "", show: false });

  
  
  const {execute: updateCollectionDay, error: updateCollectionError, isLoading: isUpdatingCollectionDay} = useApi<CheckoutRes>({
    onSuccess(data){
      updateState({checkoutSession: data});
      const formValues = getValues();
      processPayment(_directDebitUKClient!.process('67dbd513-10a4-4545-b1fe-a08f37cc66d4', state.checkoutSession.id!, CHECKOUT_SCOPES[state.currentDonationFlow], {
        bankAccount:{
          accountHolder: formValues.accountName,
          accountNumber: formValues.accountNumber,
          sortCode: formValues.sortCode
        }
      }))
    }
  });
  
  const {execute: processPayment, error: processingError, isLoading: isProcessing} = useApi<NonePaymentFlowRes>({
    async onSuccess(data: NonePaymentFlowRes){
      setLoading(true);
      const res = await _checkoutClient!.getById(state.checkoutSession.id!);
      updateState({ checkoutSession: res });
      setLoading(false);

      if (data.result) {
        getNextStage(res.progress?.remainingStages, nextStage => {
                  updateState({currentDonationFlow: nextStage});
                });
        handleNextStep();
        window.themeConfig?.onCheckoutStepComplete && window.themeConfig.onCheckoutStepComplete(state.currentDonationFlow, res)
      }

      if(data.error){
        if (state.currentDonationFlow === 'regularGiving') {
          setPaymentError({error: res.regularGiving?.credential?.errorMessage, show: res.regularGiving?.credential?.hasError || false})
        }
        
        if (state.currentDonationFlow === 'scheduledGiving') {
          setPaymentError({error: res.scheduledGiving?.credential?.errorMessage, show: res.scheduledGiving?.credential?.hasError || false})
        }
      }
    }
  });



  const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm({
    defaultValues: {
      accountName: '',
      sortCode: '',
      accountNumber: '',
      agreement: false,
      paymentDay: ''
    }
  });

  const onSubmit = (data: any) => {
    updateCollectionDay(_checkoutClient!.updateRegularGiving(state.checkoutSession.revisionId!, {
      firstCollectionDate: '',
      frequency: RegularGivingFrequency.Monthly,
      monthly: {collectionDay: data.paymentDay}
    }))
    
  };

  const handleChangePaymentMethod = () => {
    updateState({
      selectedPaymentMethod: null,
      currentStep: StepScreens.DonationSummary,
    });
  };

  const handleNextStep = () => {
    goToNextStep();  
  }

  const payment = state.checkoutProfile?.payments.paymentMethods.find(p => p.processorId === PAYMENT_PROCESSORS.DirectDebitUK)
  
    const isRegularGiving = state.currentDonationFlow === "regularGiving";
    const isScheduledGiving = state.currentDonationFlow === "scheduledGiving";
  
    const renderDays = (() => {
      if (isRegularGiving) {
        return (payment?.collectionDaysOfMonth.map((day) => (
            <SelectItem key={day.dayOfMonth.id} value={day.dayOfMonth.id}>
              {day.dayOfMonth.name}
            </SelectItem>
          ))
        )
      }
  
      if (isScheduledGiving) {
        return (payment?.collectionDaysOfWeek.map((day) => (
          <SelectItem key={day.dayOfWeek.id} value={day.dayOfWeek.id}>
            {day.dayOfWeek.name}
          </SelectItem>
        ))
      )
      }
  
    })() || null;


  if (paymentError.show) {
    return <PaymentError error={paymentError.error} onPaymentMethodChange={handleChangePaymentMethod} onPaymentRetry={() => setPaymentError({show: false, error: null})} />
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay
        isLoading={isUpdatingCollectionDay || isProcessing || loading}
        loadingText=""
      />
      <Drawer.Content>
        <div className="flex-1 space-y-6 p-5">
          <h1 className="text-2xl font-bold">
            {formatMessage("directDebit.title")}
          </h1>

          <p className="text-gray-600">
            {formatMessage("directDebit.description")}
          </p>

          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="accountName">
                {formatMessage("directDebit.accountHolderName")}
              </Label>
              <Input
                id="accountName"
                className={cn(
                  "h-12",
                  errors.accountName ? "border-red-500" : ""
                )}
                {...register("accountName", { required: true })}
              />
            </div>

            <div className="flex gap-4">
              <div className="flex-1 space-y-2">
                <Label htmlFor="sortCode">
                  {formatMessage("directDebit.sortCode")}
                </Label>
                <Input
                  id="sortCode"
                  placeholder={formatMessage(
                    "directDebit.sortCode.placeholder"
                  )}
                  className={cn(
                    "h-12",
                    errors.sortCode ? "border-red-500" : ""
                  )}
                  {...register("sortCode", {
                    required: true,
                    pattern: /^\d{6}$/,
                  })}
                  inputMode="numeric"
                  maxLength={8}
                />
              </div>
              <div className="flex-1 space-y-2">
                <Label htmlFor="accountNumber">
                  {formatMessage("directDebit.accountNumber")}
                </Label>
                <Input
                  id="accountNumber"
                  placeholder={formatMessage(
                    "directDebit.accountNumber.placeholder"
                  )}
                  className={cn(
                    "h-12",
                    errors.accountNumber ? "border-red-500" : ""
                  )}
                  {...register("accountNumber", {
                    required: true,
                    pattern: /^\d{8}$/,
                  })}
                />
              </div>
            </div>

            <div className="bg-primary/5 p-4 rounded-lg space-y-2">
              <div className="flex items-center justify-between gap-3">
                <div className="space-y-1">
                  <p className="text-sm">
                    {formatMessage("directDebit.guarantee")}
                  </p>
                  <Dialog open={showGuarantee} onOpenChange={setShowGuarantee}>
                    <DialogTrigger asChild>
                      <button className="text-sm underline">
                        {formatMessage("directDebit.guarantee.title")}
                      </button>
                    </DialogTrigger>
                    <DialogContent className="sm:max-w-md">
                      <DialogHeader>
                        <DialogTitle className="flex items-center gap-3">
                          <img
                            src="https://res.cloudinary.com/rn3o/image/upload/v1733235586/direct-debit-logo_c0mqnm.png"
                            alt="Direct Debit Logo"
                            width={80}
                            height={40}
                            className="object-contain"
                          />
                          {formatMessage("directDebit.guarantee.title")}
                        </DialogTitle>
                      </DialogHeader>
                      <div className="space-y-4">
                        <p className="text-sm">
                          {formatMessage("directDebit.guarantee.line1")}
                        </p>
                        <p className="text-sm">
                          {formatMessage("directDebit.guarantee.line2")}
                        </p>
                        <p className="text-sm">
                          {formatMessage("directDebit.guarantee.line3")}
                        </p>
                      </div>
                      <Button
                        className="w-full mt-4"
                        onClick={() => setShowGuarantee(false)}
                      >
                        {formatMessage("common.close")}
                      </Button>
                    </DialogContent>
                  </Dialog>
                </div>
                <img
                  src="https://res.cloudinary.com/rn3o/image/upload/v1733235586/direct-debit-logo_c0mqnm.png"
                  alt="Direct Debit Logo"
                  width={80}
                  height={40}
                  className="object-contain"
                />
              </div>
              <div
                className={cn(
                  "flex items-start gap-3 border-t pt-4",
                  errors.agreement ? "text-red-500" : ""
                )}
              >
                <Checkbox
                  id="agreement"
                  className="mt-1"
                  {...register("agreement", { required: true })}
                  onCheckedChange={(value) => {
                    setValue("agreement", value as boolean);
                  }}
                />
                <Label htmlFor="agreement" className="text-sm leading-relaxed">
                  {formatMessage("directDebit.agreement")}
                </Label>
              </div>
            </div>

            <div className="bg-primary/5 p-4 rounded-lg space-y-2">
              <Label>{formatMessage("directDebit.paymentCollection")}</Label>
              <div className="flex items-center gap-2">
                <p className="text-sm text-gray-600">
                  {formatMessage("directDebit.paymentCollection.description")}
                </p>
                <Select
                  onValueChange={(value) => setValue("paymentDay", value)}
                >
                  <SelectTrigger
                    className={cn(
                      "h-12 w-40",
                      errors.paymentDay ? "border-red-500" : ""
                    )}
                    {...register("paymentDay", { required: true })}
                  >
                    <Calendar className="mr-2 h-4 w-4" />
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>{renderDays}</SelectContent>
                </Select>
              </div>
            </div>
          </div>
        </div>

        {paymentError.show && (
          <ServerError error={{ message: paymentError.error }} />
        )}
        {updateCollectionError && <ServerError error={updateCollectionError} />}
        {processingError && <ServerError error={processingError} />}
      </Drawer.Content>
      <Drawer.Footer>
        <Button
          variant="outline"
          className="w-full"
          onClick={handleChangePaymentMethod}
        >
          {formatMessage("common.button.changePaymentMethod")}
        </Button>

        <div className="flex gap-2">
          <Button variant="outline" size="lg" onClick={goToPreviousStep}>
            <ArrowLeft className="mr-2 h-4 w-4" />
          </Button>
          <Button size="lg" className="flex-1" type="submit">
            {formatMessage("directDebit.confirm")}
            <ArrowRight className="ml-2 h-4 w-4" />
          </Button>
        </div>
      </Drawer.Footer>
    </form>
  );
}
