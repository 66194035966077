import { AccountMatchCriteria, AccountRes } from '@n3oltd/k2.accounts.sdk.public';
import { AccountType } from '@n3oltd/karakoram.checkout.sdk.checkout';
import { DonorRecord, PersonalInfoState } from '../types';
import { useGetAccounts } from '@/api/accounts/useGetAccount';

type UseAccountManagementProps = {
  infoState: PersonalInfoState;
  updateInfoState: (newState: Partial<PersonalInfoState>) => void;
  setValue: (name: string, value: any) => void;
};

export const useAccountManagement = ({
  infoState,
  updateInfoState,
  setValue
}: UseAccountManagementProps) => {
  const { fetchAccoucts } = useGetAccounts();

  const findAddress = async (req: AccountMatchCriteria) => {
    updateInfoState({ loadings: { loadingAccounts: true } });

    const res = await fetchAccoucts(req).finally(() => {
      updateInfoState({ loadings: { loadingAccounts: false } });
    });

    if (!res?.error && res?.result) {
      if (res.result.length === 0) {
        return;
      }

      const hasMultipleAccounts = res.result.length > 1;

      if (res.result.length === 1) {
        setAccountFormValues(res.result[0]);
      }

      updateInfoState({
        showAccountSelection: hasMultipleAccounts,
        accounts: res.result,
        isManualAddress: true,
        phoneCountry: !hasMultipleAccounts ? res.result[0].telephone?.country : undefined,
        accountId: !hasMultipleAccounts ? res.result[0].id : undefined
      });
    }
  };

  const setAccountFormValues = (account: AccountRes | null) => {
    if (!account) {
      return;
    }

    if (infoState.verificationFor === 'email' && account?.telephone?.number) {
      setValue('phone', account.telephone?.number);
    }
    
    if (infoState.verificationFor === 'phone' && account?.email?.address) {
      setValue('email', account?.email?.address);
    }

    if (account.type === AccountType.Individual) {
      if (account?.individual?.name?.title) {
        setValue('title', account?.individual?.name?.title);
      }
  
      if (account?.individual?.name?.firstName) {
        setValue('firstName', account?.individual?.name?.firstName);
      }
  
      if (account?.individual?.name?.lastName) {
        setValue('lastName', account?.individual?.name?.lastName);
      }  
    }

    if (account.type === AccountType.Organization) {
      if (account?.organization?.contact?.title) {
        setValue('title', account?.organization?.contact?.title);
      }
  
      if (account?.organization?.contact?.firstName) {
        setValue('firstName', account?.organization?.contact?.firstName);
      }
  
      if (account?.organization?.contact?.lastName) {
        setValue('lastName', account?.organization?.contact?.lastName);
      }  
    }

    if (account?.address?.line1) {
      setValue('addressLine1', account?.address?.line1);
    }

    if (account?.address?.line2) {      
      setValue('addressLine2', account?.address?.line2);
    }
    
    if (account?.address?.line3) {      
      setValue('addressLine3', account?.address?.line3);
    }

    if (account?.address?.country) {
      setValue('country', account?.address?.country);
    }

    if (account?.address?.postalCode) {
      setValue('postCode', account?.address?.postalCode);
    }

    if (account?.address?.locality) {
      setValue('city', account?.address?.locality);  
    }
  };

  const handleAccountSelected = (record: DonorRecord) => {
    const account = infoState.accounts.find(acc => acc.reference === record.reference);
    setAccountFormValues(account || null);

    updateInfoState({
      showAccountSelection: false,
      accountId: account?.id,
      phoneCountry: account?.telephone?.country
    });
  };

  const mapToDonorRecords = (accounts: AccountRes[]): DonorRecord[] => {
    return accounts.map(account => {
      return {
        name: account.type === AccountType.Individual ? 
          `${account.individual?.name?.title || ''} ${account.individual?.name?.firstName} ${account.individual?.name?.lastName}` : 
          `${account.organization?.contact?.title || ''} ${account.organization?.contact?.firstName || ''} ${account.organization?.contact?.lastName || ''}`,
        phone: account.telephone,
        address: account.address,
        reference: account.reference,
        created: account.openedOn || 'N/A'
      } as DonorRecord;
    });
  };

  return {
    findAddress,
    handleAccountSelected,
    mapToDonorRecords
  };
};