export default {
  
    // Common
    'common.button.back': 'Back',
    'common.button.continue': 'Continue',
    'common.button.changePaymentMethod': 'Change Payment Method',  
    'common.loading': 'Loading...',
    'common.error': 'Error',
    'common.creditCard': 'Credit Card',
    'common.directDebit': 'Direct Debit', 
    'common.applePay': 'Apple Pay',
    'common.bankTransfer': 'Instant UK Bank Transfer',
    'common.googlePay': 'Google Pay',
    'common.paid': 'Paid',
    'common.awaitingPayment': 'Awaiting Payment',
    'common.donation': 'Single Donation',
    'common.regularGiving': 'Regular Donation',
    'common.scheduledGiving': 'Scheduled Donation',
    'common.thankYou': 'Thank You',
    'common.done': 'Done',
    'common.pleaseWait': 'Please wait...',
    'common.available': 'Available',
    'common.unavailable': 'Unavailable',
    'common.month': 'month',
    'common.for': 'for',
    'common.processing': 'Processing...',
    'common.close': 'Close',
    'common.checkout': 'Checkout',
    // Main
    'app.title': 'Donation Checkout',
    'app.button.startDonation': 'Start Donation',
    'app.button.continueDonation': 'Continue Donation',
    'app.loading': 'Loading...',
    'app.error.paymentMethods': 'Failed to load payment methods. Please try again.',

    // Payment Options
    'payment.summary.title': 'Donation Summary',
    'payment.summary.singleDonation': 'Single Donation',
    'payment.summary.regularDonation': 'Regular Giving',
    'payment.summary.scheduleDonation': 'Schedule Giving',
    'payment.warning.multiple.line1': 'Your basket contains multiple donation types.',
    'payment.warning.multiple.line2': 'To proceed, please complete each payment individually.',
    'payment.viewDonation': 'View Donation Basket',
    'payment.failed': 'Payment Falied',
    'payment.retry': 'Retry Payment',
    
    // Payment Methods
    'payment.method.applePay': 'Apple Pay',
    'payment.method.googlePay': 'Google Pay',
    'payment.method.creditCard': 'Credit/Debit Card',
    'payment.method.directDebit': 'Direct Debit',
    'payment.method.bankTransfer': 'Instant UK Bank Transfer',
    'payment.button.singleDonation': 'Pay Single Donation',
    'payment.button.regularPayment': 'Setup Regular Payment',
    'payment.amount.monthly': '{amount}/month',

    // Organization Donation
    'org.checkbox.label': 'Donate on behalf of an organization',
    'org.input.name': 'Organization Name',
    'org.input.namePlaceholder': 'Enter organization name',
    'org.input.type': 'Organization Type',
    'org.input.typePlaceholder': 'Select organization type',
    'org.type.trust': 'Trust',
    'org.type.company': 'Company',
    'org.type.charity': 'Charity',

    // Gift Aid
    'giftAid.boostOption': 'Yes, I wish to claim Gift Aid on my donations',
    'giftAid.boost': 'Boost your donation by',
    'giftAid.free': 'FREE',
    'giftAid.description.line1': 'By selecting Yes I confirm I am a UK taxpayer and would like to claim Gift Aid on this donation and all donations made in the last 4 years. I understand that if I pay less Income and/or Capital Gains Tax than the amount of Gift Aid claimed on all my donations in the relevant tax year, it is my responsibility to pay any difference. I understand that Gift Aid income is used towards the costs of the charity and/or the General Fund. Please notify us if you wish to cancel this declaration, change your name/address or no longer pay sufficient tax.',
    'giftAid.link': 'Read more about gift aid',
    'giftAid.error': 'Please select one of these options',
    'giftAid.orgMessage': 'We are unable to claim Gift Aid on donations on behalf of organisations.',
    'giftAid.noClaim': `No, I don't want Gift Aid on my donations`,
    'giftAid.unsure': `I am not sure, don't claim Gift Aid at the moment`,

    // Contact Preference
    'contact.option.email': 'Email',
    'contact.option.sms': 'SMS',

    // Terms & Conditions
    'terms.checkbox.label': 'I agree to the',
    'terms.link': 'terms & conditions',
    'terms.error': 'Please agree to the terms & conditions to continue',
    'terms.chooseOption': `Please choose which option you'd like to complete first.`,


    // Basket
    'basket.title': 'Your Donation Basket',
    'basket.oneOff.title': 'One-off Donation',
    'basket.regular.title': 'Regular Donation',
    'basket.amount': 'Amount',
    'basket.frequency': 'Frequency',
    'basket.frequency.monthly': 'Monthly',
    'basket.frequency.quarterly': 'Quarterly',
    'basket.frequency.annually': 'Annually',
    'basket.adminFee.title': 'Administration Fee',
    'basket.adminFee.description': 'Optional 10% contribution towards our administrative costs',
    'basket.total': 'Total',
    'basket.button.donate': 'Donate {amount}',
    'basket.button.setup': 'Set up {frequency} donation of {amount}',
    'basket.empty': 'Your basket is empty',
    'basket.error.loading': 'Error loading basket details',
    'basket.upsell.title': 'Care to contributing to...',
    'basket.adminFee.checkboxLabel': 'I want to contribute to the admin fee',
    'basket.button.checkout': 'Checkout',
    'basket.oneOff.description': 'This is a description',
    'basket.oneOff.name': 'John Doe',


    // Personal Info
    'personalInfo.heading': 'Please enter your details',
    'personalInfo.email': 'Email',
    'personalInfo.phone': 'Phone',
    'personalInfo.button.continue': 'Continue',
    'personalInfo.name': 'Your Name',
    'personalInfo.title': 'Title',
    'personalInfo.title.mr': 'Mr',
    'personalInfo.title.mrs': 'Mrs',
    'personalInfo.title.ms': 'Ms',
    'personalInfo.title.dr': 'Dr',
    'personalInfo.firstName': 'First Name',
    'personalInfo.lastName': 'Last Name',
    'personalInfo.error.firstName': 'Please enter your first name',
    'personalInfo.error.lastName': 'Please enter your last name',
    'personalInfo.address': 'Address',
    'personalInfo.findAddress': 'Find your address',
    'personalInfo.fillManually': 'or fill manually',
    'personalInfo.addressSearch': 'Start typing your address',
    'personalInfo.addressLine1': 'Address Line 1',
    'personalInfo.addressLine2': 'Address Line 2',
    'personalInfo.addressLine3': 'Address Line 3',
    'personalInfo.area': 'Area',
    'personalInfo.city': 'City',
    'personalInfo.postcode': 'Postcode',
    'personalInfo.country': 'Country',
    'personalInfo.error.email': 'Invalid email',
    'personalInfo.error.emailRequired': 'Email is required',
    'personalInfo.error.phoneCodeRequired': 'Phone code is required',
    'personalInfo.error.phoneMin': 'Phone number must be at least 5 characters',
    'personalInfo.error.phoneRequired': 'Phone number is required',
    'personalInfo.error.titleRequired': 'Title is required',
    'personalInfo.error.firstNameRequired': 'First Name is required',
    'personalInfo.error.lastNameRequired': 'Last Name is required',
    'personalInfo.error.firstNameMin': 'First name must be at least 2 characters',
    'personalInfo.error.lastNameMin': 'Last name must be at least 2 characters',
    'personalInfo.error.addressRequired': 'Address is required',
    'personalInfo.error.cityRequired': 'City is required',
    'personalInfo.error.postcodeRequired': 'Postcode is required',
    'personalInfo.error.countryRequired': 'Country is required',
    'personalInfo.accountUpdate': 'Updating Account',
    'personalInfo.accountSkip': 'If none of the above are correct, you can create a new account',

    // OTP Verification
    'otp.message': 'One Time code has been sent to',
    'otp.confirm': 'Confirm',
    'otp.enterCode': 'Please enter the code you received below',
    'otp.incorrectCode': 'Incorrect code',
    'otp.note1': 'If you have donated before, ',
    'otp.note2': 'we will retrieve your existing account details',
    'otp.skip': 'Skip and enter manually',

    // Account Selection
    'account.selectDetails': 'Select your details',
    'account.selectDetails.description': 'We found multiple donor records matching your details.',
    'account.reference': 'Reference No. {reference}',
    'account.created': 'Created: {created}',
    'account.lastDonation': 'Last Donation {lastDonation}',

    // Direct Debit
    'directDebit.title': 'Setup Direct Debit',
    'directDebit.description': 'Donating monthly by Direct Debit (UK bank accounts only) is the simplest and most cost effective way to give regularly to Charity Name. You may cancel at any time.',
    'directDebit.accountHolderName': 'Account Holder Name',
    'directDebit.accountHolderName.placeholder': '',
    'directDebit.sortCode': 'Sortcode',
    'directDebit.sortCode.placeholder': '000000',
    'directDebit.accountNumber': 'Account Number',
    'directDebit.accountNumber.placeholder': '12345678',
    'directDebit.accountName': 'Account Name',
    'directDebit.accountName.placeholder': 'Full Name on your card',
    'directDebit.guarantee': 'Your payments are protected by the',
    'directDebit.address': '234 Walkable Street, Otherborough',
    'directDebit.postcode': 'United Land, EA17EB',
    'directDebit.email': 'support@charitywebsite.org',
    'directDebit.agreement': `I wish to set-up an automated Direct Debit instruction and have
                read the Direct Debit Guarantee shown. I confirm that I am the
                named account holder and there are no other signatures apart
                from my own to authorise the setting up of a Direct Debit on
                this account.`,
    'directDebit.paymentCollection': 'Payment Collection',
    'directDebit.paymentCollection.description': 'You can choose the specific day of the month for your payment to be collected.',
    'directDebit.paymentCollection.placeholder': 'Day of the month',
    'directDebit.confirm': 'Confirm Direct Debit',
    'directDebit.guarantee.title': 'Direct Debit Guarantee',
    'directDebit.guarantee.line1': `Direct Debit Guarantee This Guarantee is offered by all
                        Banks and Building Societies that take part in the
                        Direct Debit Scheme. The efficiency and security of the
                        scheme is monitored by your own Bank or Building
                        Society.`,
    'directDebit.guarantee.line2': `If the amount to be paid or the payment date changes we
                        will notify you 5 (five) working days in advance of your
                        account being debited, or as otherwise agreed. If an
                        error is made by us or your Bank or Building Society,
                        you are guaranteed a full and immediate refund from your
                        branch of the amount paid.`,
    'directDebit.guarantee.line3': `You can cancel a Direct Debit at any time by writing to
                        your Bank or Building Society. Please send a copy of the
                        letter to us.`,

    // Credit Card
    'creditCard.title': 'Credit Card Payment',
    'creditCard.description': 'Please enter your card details below',
    'creditCard.cardNumber': 'Card Number',
    'creditCard.cardNumber.placeholder': '1234 1234 1234 1234',
    'creditCard.expiration': 'Expiration (MM/YY)',
    'creditCard.expiration.placeholder': 'MM / YY',
    'creditCard.cvv': 'Security Code  (Back of Card)',
    'creditCard.cardholderName': 'Cardholder Name',
    'creditCard.cardholderName.placeholder': 'Full Name on your card',
    'creditCard.postcode': 'Postcode',
    'creditCard.postcode.placeholder': 'Postcode',
    'creditCard.paymentCollection': 'Payment collection',
    'creditCard.paymentCollection.placeholder': 'Select collection date',
    'creditCard.paymentCollection.description': 'You can choose the specific day of the month for your payment to be collected.',
    'creditCard.button.makePayment': 'Make Payment',
    'creditCard.paymentDay.required': 'Please select payment day',
    'creditCard.nmi.tokenizationKey.error': 'tokenizationKey must be prodived',

    //Bank Transfer
    'bankTransfer.creatingSession': 'Creating Session...',
    'bankTransfer.verifyingPayment': 'Verifying Payment...',
    'bankTransfer.paymentError': 'Something went wrong, please try again',
    'bankTransfer.note': `You'll be redirected to your bank for payment.`,
    'bankTransfer.tabOpen': `Your checkout is open in another tab. Please return to that tab to proceed.`,

    // Donation Summary
    'donationSummary.title': 'Please make the first payment',
    'donationSummary.continuePayment': 'Please continue with the other payment',
    'donationSummary.warning': 'Please complete all the payments before leaving this page.',

    // Thank You
    'thankYou.title': 'Payment Confirmed',
    'thankYou.message': 'A receipt for your donation has been sent to {email}.',
    'thankYou.message2': 'Your contribution makes a world of difference.',
} as const; 
