import React, { ReactNode, useState } from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'
import { LOCALES, LocaleType, messages, getDefaultLocale } from './config'

export const IntlContext = React.createContext<{
    locale: LocaleType;
    setLocale: (locale: LocaleType) => void;
}>({
    locale: getDefaultLocale(),
    setLocale: () => void 0,
})

interface Props {
    children: ReactNode
}

export function IntlProvider({ children }: Props) {
    const [locale, setLocale] = useState<LocaleType>(getDefaultLocale())

    return (
        <IntlContext.Provider value={{ locale, setLocale }}>
            <ReactIntlProvider
                messages={messages[locale]}
                locale={LOCALES[locale]}
                defaultLocale={LOCALES.ENGLISH}
            >
                {children}
            </ReactIntlProvider>
        </IntlContext.Provider>
    )
} 