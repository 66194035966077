import { InputOTP, InputOTPGroup, InputOTPSlot, Button } from "@n3oltd/n3o-ui-components";
import { useTranslation } from "@/i18n";
import { OTPVerficationType } from "@/checkout/types";

type OTPProps = {
  email: string;
  phone: string;
  verificationFor: OTPVerficationType | null;
  handleOTPComplete: (code: string) => void;
  isWrongOTP: boolean;
  onSkip: () => void;
}

export function OTPVerification({
  email,
  phone,
  verificationFor,
  handleOTPComplete,
  isWrongOTP,
  onSkip
}: OTPProps) {
  const { formatMessage } = useTranslation();
  return (
    <>
      {/* Overlay */}
      <div className="absolute inset-0 bg-black/50 z-10 backdrop-blur-sm" />

      {/* OTP Modal */}
      <div className="absolute left-1/2 top-1/3 -translate-x-1/2 -translate-y-1/2 w-[90%] max-w-md z-20 space-y-3">
        {/* Message Section */}
        <div className="bg-white rounded-lg p-4">
          <p className="text-center text-sm text-gray-600">
            {formatMessage('otp.message')}
            <span className="font-bold ml-1">
              {verificationFor === 'email' ? email : phone}
            </span>
          </p>
        </div>

        {/* OTP Input Section */}
        <div className="bg-white rounded-lg p-6 flex flex-col gap-4 items-center">
          {/* Header */}
          <div className="space-y-2">
            <p className="text-center font-semibold text-xl">
              {formatMessage('otp.confirm')}
            </p>
            <p className="text-center text-sm text-gray-600">
              {formatMessage('otp.enterCode')}
            </p>
          </div>

          {/* OTP Input */}
          <InputOTP
            maxLength={6}
            className="gap-3"
            onComplete={handleOTPComplete}
          >
            <InputOTPGroup className="flex gap-3 justify-center">
              {Array.from({ length: 6 }).map((_, i) => (
                <InputOTPSlot
                  key={i}
                  index={i}
                  className={`
                    rounded-lg w-10 h-12 text-lg border
                    ${isWrongOTP ? 'border-red-500' : 'border-gray-200'}
                    focus:border-primary focus:ring-primary
                  `}
                />
              ))}
            </InputOTPGroup>
          </InputOTP>

          {/* Error Message */}
          {isWrongOTP && (
            <p className="text-center text-sm text-red-500">
              {formatMessage('otp.incorrectCode')}
            </p>
          )}

          {/* Note */}
          <p className="text-center text-sm text-gray-600">
            {formatMessage('otp.note1')}
            <br />
            {formatMessage('otp.note2')}
          </p>

          {/* Skip Button */}
          <Button
            variant="outline"
            className="w-full"
            onClick={onSkip}
          >
            {formatMessage('otp.skip')}
          </Button>
        </div>
      </div>
    </>
  );
}


