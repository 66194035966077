import React from "react";

import { Preference, AccountType, ElementsPreferenceSelectionReq, ElementsOrganizationReq, CheckoutAccountReq, CheckoutRes, CheckoutStage } from "@n3oltd/karakoram.checkout.sdk.checkout";
import {Checkbox, ContactPreference, DonationSummaryCard, GiftAid, Label, OrganizationDonationOption, Warning, PaymentOption, Button} from "@n3oltd/n3o-ui-components"
import { BooleanPaymentFlowRes, DigitalWalletType, NMFlowPaymentReq, PaymentChannel } from "@n3oltd/karakoram.checkout.sdk.network-merchants";

import { Drawer } from "./Drawer"
import { ServerError } from "@/common/components/Error";
import { PaymentButtons } from "./payments/PaymentButtons";
import { LoadingOverlay } from "@/Loader";
import { useTranslation } from '@/i18n';
import { useApi } from "@/api/common/hooks/useApi";
import { useGiftAidText, useOrganizationInfo, usePreferences } from "../hooks";
import { LookupService } from "@/api/common/utils/Lookup";
import { _checkoutClient, _networkMerchantsClient } from "@/api/common/clients/K2RestClient";
import { giftAidClaimOptions } from "../giftaid";
import Idempotency from "../utils/Idempotency";
import { isAppleOrGooglePay } from "../utils/payments";
import { AGConfig } from "./payments/AppleGooglePay";
import { CHECKOUT_SCOPES, PAYMENT_PROCESSORS } from "../consntants";
import { CheckoutState, CollectJSPaymentToken, DonationKeys, PaymentType } from "../types";
import { ArrowLeft } from "lucide-react";

type Props = {
  state: CheckoutState;
  updateState: (newState: Partial<CheckoutState>) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  setIsSheetOpen: (isOpen: boolean) => void;
}


export function PaymentOptions({ state, updateState, goToNextStep, setIsSheetOpen, goToPreviousStep }: Props) {  
  const [showGiftAidSelectionError, setShowGiftAidSelectionError] = React.useState(false);
  const [termSelectionError, setTermSelectionError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
    const [paymentError, setPaymentError] = React.useState<{
      error: any;
      show: boolean;
    }>({show: false, error: ''});

  const checkoutSession = React.useRef<CheckoutRes>(state.checkoutSession);

  const { formatMessage, formatCurrency } = useTranslation();
  const { giftAidScheme, getGiftAidText } = useGiftAidText({ state });
	const { updateOrgInfo } = useOrganizationInfo({ state, updateState });
	const { updatePrefs } = usePreferences({ state, updateState });

  const {execute, error: updateAccountError, isLoading: isUpdatingInfo} = useApi<CheckoutRes>({
    onSuccess(data) {
      updateState({checkoutSession: data})
      checkoutSession.current = data;
    },
  });

  const {
      execute: processPayment,
      error: paymentProcessingError,
      isLoading: isProcessingPayment,
    } = useApi<BooleanPaymentFlowRes>({
      async onSuccess(data: BooleanPaymentFlowRes) {
        setLoading(true);
        const res = await _checkoutClient!.getById(state.checkoutSession.id!);

        updateState({ checkoutSession: res });

        checkoutSession.current = res;

        if (data.result) {
          
          setLoading(false);
          goToNextStep();
        }
  
        if(data.error){
          setLoading(false);          
          setPaymentError({error: res.donation?.payment?.errorMessage, show: res.donation?.payment?.hasError || false})
        }
      },
    });

  const handleNextStep = (paymentType: PaymentType) => {
    updateState({
      selectedPaymentMethod: paymentType,
    });

    if (!state.personalInfo?.terms) {
      setTermSelectionError(true);
      return;
    }

    if ((giftAidScheme && !state.checkoutInfo.giftAidSelection) && !state.checkoutInfo.isOrgSelected) {
      setShowGiftAidSelectionError(true);
      return;
    }

    if (!state.checkoutSession.progress?.remainingStages?.includes(CheckoutStage.Account)) {
      goToNextStep();
      return;
    }

    if (isAppleOrGooglePay(paymentType)) {
      return;
    }

    updateCheckout()
      .then(() => {
        goToNextStep();
      })
      .catch(console.error);
  };

  const handleDonationFlow = (donationFlow: DonationKeys) => {
    if (isCombinedDonation && !state.personalInfo?.terms) {
      setTermSelectionError(true);
      return;
    }

    if (termSelectionError) {
      setTermSelectionError(false);
    }

    if ((giftAidScheme && !state.checkoutInfo.giftAidSelection) && !state.checkoutInfo.isOrgSelected) {
      setShowGiftAidSelectionError(true);
      return;
    }

    updateCheckout()
      .then(() => {
        updateState({
          currentDonationFlow: donationFlow,
        });
        goToNextStep();      })
      .catch(console.error);
  }

  const updateCheckout = () => {

    let orgReq: ElementsOrganizationReq  = {};

    const payload: CheckoutAccountReq = {
      type: state.checkoutInfo.accountType,
      taxStatus: {
        canClaim: giftAidClaimOptions.getValue(state.checkoutInfo.giftAidSelection!) as any,
      },
      preferences: {
        selections: getPrefSelections(),
      },
      submit: false 
    }

    if (state.checkoutInfo.accountType === AccountType.Organization) {
      orgReq = {
          name: state.checkoutInfo.org?.name,
          type: state.checkoutInfo.org?.type
      }

      payload.organization = orgReq;
    }

    const req = _checkoutClient!.updateAccount(checkoutSession.current.revisionId || '', payload);

    return execute(req);

    function getPrefSelections(): ElementsPreferenceSelectionReq[] | undefined {
      const options = state.checkoutProfile?.accounts.preferences.options;
      if (!options) {
        return undefined;
      }
    
      const prefs = state.checkoutInfo.prefs || {};
      const prefSelections: ElementsPreferenceSelectionReq[] = [];

      options.forEach(option => {
        option.categories.forEach((category) => {
          const isSelected = prefs[option.channel];

          prefSelections.push({
            channel: option.channel,
            category: category,
            response:
              isSelected === undefined
                ? Preference.NoResponse
                : isSelected
                ? Preference.OptIn
                : Preference.OptOut,
          } as ElementsPreferenceSelectionReq);
        });
      });
     
      return prefSelections;
    }
  };

  const processDonationPayment = async (event: CollectJSPaymentToken) => {
    await updateCheckout();

    const donationReqPayload: NMFlowPaymentReq = {
      idempotencyKey: Idempotency.createCardKey(state.checkoutSession.id!, state.checkoutSession.donation?.total?.amount!),
      card: {
        cardBrand: event.card.type,
        channel: PaymentChannel.Ecommerce,
        token: event.token,
        walletType: event.tokenType as DigitalWalletType,
      },
      value: state.checkoutSession.donation?.total,
    };
    
    const donationReq = _networkMerchantsClient!.processPayment(
      payment!.id,
      state.checkoutSession.id!,
      CHECKOUT_SCOPES[state.currentDonationFlow],
      donationReqPayload
    );
    
    processPayment(donationReq);
  }

  const handlePaymentClick = (selected: PaymentType | DonationKeys) => {
    if (Object.values(PaymentType).includes(selected as PaymentType)) {
      handleNextStep(selected as PaymentType);

      return;
    }

    handleDonationFlow(selected as DonationKeys);
  }

  const donationType = state.donationType;

  const isCombinedDonation = donationType === 'oneOffAndRegular';

  const hasDonation = !!state.checkoutSession.donation?.cartItems?.length;
  const hasRegularGiving = !!state.checkoutSession.regularGiving?.cartItems?.length;
  const hasScheduleGiving = !!state.checkoutSession.scheduledGiving?.cartItems?.length;


  const payment = state.checkoutProfile?.payments.paymentMethods.find(p => p.processorId === PAYMENT_PROCESSORS.NMI)


  return (
    <>
      <LoadingOverlay
        isLoading={isUpdatingInfo || isProcessingPayment || loading}
        loadingText={
          isUpdatingInfo ? formatMessage("personalInfo.accountUpdate") : ""
        }
      />
      <Drawer.Header>
        {formatMessage("common.checkout")}
      </Drawer.Header>
      <Drawer.Content>
        <DonationSummaryCard>
          <DonationSummaryCard.Title>
            {formatMessage("payment.summary.title")}
          </DonationSummaryCard.Title>
          <div className="space-y-4">
            {hasDonation && (
              <DonationSummaryCard.Content>
                <DonationSummaryCard.Description>
                  {formatMessage("payment.summary.singleDonation")}
                </DonationSummaryCard.Description>
                <DonationSummaryCard.Amount
                  value={formatCurrency(
                    state.checkoutSession.donation?.total?.amount || 0,
                    state.checkoutSession.currency || ""
                  )}
                />
              </DonationSummaryCard.Content>
            )}

            {hasRegularGiving && (
              <DonationSummaryCard.Content>
                <DonationSummaryCard.Description>
                  {formatMessage("payment.summary.regularDonation")}
                </DonationSummaryCard.Description>
                <DonationSummaryCard.Amount
                  value={formatCurrency(
                    state.checkoutSession.regularGiving?.total?.amount || 0,
                    state.checkoutSession.currency || ""
                  )}
                />
              </DonationSummaryCard.Content>
            )}

            {hasScheduleGiving && (
              <DonationSummaryCard.Content>
                <DonationSummaryCard.Description>
                  {formatMessage("payment.summary.scheduleDonation")}
                </DonationSummaryCard.Description>
                <DonationSummaryCard.Amount
                  value={formatCurrency(
                    state.checkoutSession.scheduledGiving?.total?.amount || 0,
                    state.checkoutSession.currency || ""
                  )}
                />
              </DonationSummaryCard.Content>
            )}

            {isCombinedDonation && (
              <Warning>
                <Warning.Line>
                  {formatMessage("payment.warning.multiple.line1")}
                </Warning.Line>
                <Warning.Line>
                  {formatMessage("payment.warning.multiple.line2")}
                </Warning.Line>
              </Warning>
            )}
          </div>
        </DonationSummaryCard>

        <div className="space-y-4">
          <OrganizationDonationOption
            initialState={{
              isChecked: state.checkoutInfo.isOrgSelected!,
            }}
            onChange={(value) => {
              updateState({
                checkoutInfo: {
                  ...state.checkoutInfo,
                  isOrgSelected: value.isChecked,
                  accountType: value.isChecked
                    ? AccountType.Organization
                    : AccountType.Individual,
                  ...(value.isChecked && { giftAidSelection: "" }),
                },
              });
            }}
          >
            <div className="flex items-center space-x-2">
              <OrganizationDonationOption.Checkbox />
              <OrganizationDonationOption.Label>
                {formatMessage("org.checkbox.label")}
              </OrganizationDonationOption.Label>
            </div>
            <OrganizationDonationOption.Details>
              <OrganizationDonationOption.Input
                id="name"
                value={state.checkoutInfo.org?.name}
                label={formatMessage("org.input.name")}
                placeholder={formatMessage("org.input.namePlaceholder")}
                onBlur={(e) => updateOrgInfo("name", e.target.value)}
              />
              <OrganizationDonationOption.Select
                id="orgType"
                value={state.checkoutInfo.org?.type}
                label={formatMessage("org.input.type")}
                placeholder={formatMessage("org.input.typePlaceholder")}
                options={LookupService.getOrganisationTypes().map((type) => ({
                  value: type.Id,
                  label: type.Name,
                }))}
                onChange={(value) => updateOrgInfo("type", value)}
              />
            </OrganizationDonationOption.Details>
          </OrganizationDonationOption>

          {giftAidScheme && (
            <GiftAid.Root
              isOrganization={state.checkoutInfo.isOrgSelected!}
              giftAidOption={state.checkoutInfo.giftAidSelection!}
              onGiftAidOptionChange={(selectedOption) => {
                updateState({
                  checkoutInfo: {
                    ...state.checkoutInfo,
                    giftAidSelection: selectedOption,
                    accountType: AccountType.Individual,
                  },
                });
                setShowGiftAidSelectionError(false);
              }}
            >
              {state.checkoutInfo.isOrgSelected ? (
                <GiftAid.OrganizationMessage>
                  {formatMessage("giftAid.orgMessage")}
                </GiftAid.OrganizationMessage>
              ) : (
                <>
                  <GiftAid.Options>
                    <GiftAid.Option
                      value={giftAidClaimOptions.boost}
                      icon={
                        <img
                          src={giftAidScheme?.logoUrl}
                          alt="Gift Aid"
                          className="h-6 absolute top-2 right-2"
                        />
                      }
                    >
                      {formatMessage("giftAid.boostOption")} <br />
                      {formatMessage('giftAid.boost')}&nbsp; 
                      <span className="text-blue-600">
                        {getGiftAidText()}
                      </span>{" "}
                      {formatMessage("common.for")}{" "}
                      <span className="font-semibold">
                        {formatMessage("giftAid.free")}
                      </span>
                    </GiftAid.Option>

                    <GiftAid.Option value={giftAidClaimOptions.no}>
                      {formatMessage("giftAid.noClaim")}
                    </GiftAid.Option>

                    <GiftAid.Option value={giftAidClaimOptions.unsure}>
                      {formatMessage("giftAid.unsure")}
                    </GiftAid.Option>
                  </GiftAid.Options>

                  <GiftAid.BoostMessage>
                    <p className="mb-2">
                      {formatMessage("giftAid.description.line1")}
                    </p>
                  </GiftAid.BoostMessage>
                </>
              )}

              {showGiftAidSelectionError && (
                <ServerError
                  error={{ message: formatMessage("giftAid.error") }}
                />
              )}
            </GiftAid.Root>
          )}

          <ContactPreference>
            <ContactPreference.Message>
              {state.checkoutProfile?.accounts.preferences.preferenceText}
            </ContactPreference.Message>
            <ContactPreference.OptionsGroup>
              {LookupService.getCommunicationChannels(
                state.checkoutProfile?.accounts?.preferences?.options || []
              ).map((option) => (
                <ContactPreference.Option
                  key={option.id}
                  checked={state.checkoutInfo.prefs?.[option.id]}
                  id={option.id}
                  label={option.label}
                  onChange={(value, isChecked) =>
                    updatePrefs({ id: value, isChecked: isChecked })
                  }
                />
              ))}
            </ContactPreference.OptionsGroup>
            <ContactPreference.Message>
              {state.checkoutProfile?.accounts.preferences.privacyText}
            </ContactPreference.Message>
          </ContactPreference>
        </div>
        {updateAccountError && <ServerError error={updateAccountError} />}
        {paymentProcessingError?.errors &&
          paymentProcessingError.errors?.length > 0 && (
            <ServerError error={paymentProcessingError} />
          )}
        {paymentError.show && (
          <ServerError error={{ message: paymentError.error }} />
        )}
      </Drawer.Content>
      <Drawer.Footer>
        <div className="pt-2.5 gap-2 flex flex-col">
          <div className="flex flex-col space-y-3">
            <div className="flex items-center justify-center gap-2">
              <Checkbox
                id="terms"
                defaultChecked={state.personalInfo?.terms}
                onCheckedChange={(value: boolean) => {
                  setTermSelectionError(false)
                  updateState({
                    personalInfo: {
                      ...state.personalInfo,
                      terms: value,
                    },
                  });
                }}
              />
              <Label htmlFor="terms" className="text-sm">
                {formatMessage("terms.checkbox.label")}
                <button
                  className="text-blue-600 underline pl-1"
                  onClick={() => {
                    window.open(
                      state.checkoutProfile?.termsOfService?.url,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  {state.checkoutProfile?.termsOfService?.text}
                </button>
              </Label>
            </div>
            {termSelectionError && (
              <ServerError error={{ message: formatMessage("terms.error") }} />
            )}
            {isCombinedDonation && <h3 className="font-semibold text-center text-lg text-pretty">
              {formatMessage("terms.chooseOption")}
            </h3>}
          </div>

          <PaymentButtons
            donationType={state.donationType}
            checkoutProfile={state.checkoutProfile}
            checkoutSession={state.checkoutSession}
            handleClick={handlePaymentClick}
            currentFlow={state.currentDonationFlow}
            isRegularGivingPaid={
              state.checkoutSession.regularGiving?.isComplete || false
            }
            isDoantionPaid={state.checkoutSession.donation?.isComplete || false}
            config={
              {
                isGoogleApplePayDisabled:
                  giftAidScheme &&
                  !state.checkoutInfo.giftAidSelection &&
                  !state.checkoutInfo.isOrgSelected,
                country:
                  state.checkoutProfile?.accounts.address.domesticCountry || "",
                currency: state.checkoutSession.currency,
                checkoutKey: payment?.additionalData?.checkoutKey,
                token: payment?.additionalData?.tokenizationKey,
                onLoading: (l) => setLoading(l),
                onPaymentComplete(payload) {
                  processDonationPayment(payload);
                  setPaymentError({ show: false, error: "" });
                },
                onPaymentFailure(payload) {
                  setPaymentError({ show: false, error: payload });
                },
                ...(() => {
                  if (state.donationType === "oneOff") {
                    return {
                      price: `${state.checkoutSession.donation?.total?.amount}`,
                    };
                  }
                  return {};
                })(),
              } as AGConfig
            }
          />
            <>
              <PaymentOption.Divider />
              {state.legacy ? <Button
                variant="outline"
                className="w-full"
                size="lg"
                onClick={() => setIsSheetOpen(false)}
              >
                {formatMessage("common.close")}
              </Button>
              : <Button
                variant="outline"
                className="w-full"
                size="lg"
                onClick={goToPreviousStep}
              >
                <ArrowLeft className="mr-2 h-4 w-4" />
                {formatMessage("payment.viewDonation")}
              </Button>}
            </>
        </div>
      </Drawer.Footer>
    </>
  );
}