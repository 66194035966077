import * as Sentry from "@sentry/react";

export const initializeSentry = (widgetElement: HTMLElement | null) => {
  if (!widgetElement) {
    console.warn('Sentry initialization skipped: Widget element is null');
    return;
  }
  Sentry.init({
    dsn: "https://d71093cdf36b9b1c13bfc8713e58e7a8@o373343.ingest.us.sentry.io/4508919379853312",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /\.n3o\.cloud$/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    
    beforeSend: (event: Sentry.ErrorEvent, hint: Sentry.EventHint) => {
      const error = hint?.originalException;
      let element: Node | null = null;
      
      if (hint?.originalException instanceof Event && 'target' in hint.originalException) {
        element = hint.originalException.target as Node;
      } else if (hint?.originalException instanceof Error && 'target' in (hint.originalException as any)) {
        element = (hint.originalException as any).target as Node;
      }
      
      if (element instanceof Node) {
        if (!widgetElement.contains(element)) {
          return null;
        }
      } else {
        if (error && hint?.mechanism?.type === 'onunhandledrejection') {
          const errorSource = (error as Error)?.stack || '';
          if (!errorSource.includes('n3o-checkout') && 
              !errorSource.includes('/elements/checkout/') && 
              !errorSource.includes('CheckoutElementWrapper')) {
            console.debug('Sentry: Ignoring unhandled promise rejection not from our widget');
            return null;
          }
        }
        
        const errorStack = (error instanceof Error) ? error.stack || '' : '';
        if (errorStack && !errorStack.includes('n3o-checkout') && 
            !errorStack.includes('/elements/checkout/') && 
            !errorStack.includes('CheckoutElementWrapper')) {
          return null;
        }
        
        const url = event.request?.url || '';
        if (url && !url.includes('n3o-checkout') && !url.match(/\.n3o\.cloud$/)) {
          return null;
        }
      }
      
      event.tags = {
        ...event.tags,
        widget: 'n3o-checkout',
      };
      
      return event;
    },
  });
  
  Sentry.setTag("environment", "production");
  Sentry.setTag("feature", "checkout");
  Sentry.setTag("widget", "n3o-checkout");
};
