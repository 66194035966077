import { generateUuid } from "@azure/ms-rest-js";
import dayjs from "dayjs";
const serverDateFormat: string = "YYYY-MM-DD";

export default class Idempotency {
  /**
   * Creates an idempotency key that can be used to ensure a similar card payment is not created twice.
   */
  public static createCardKey(hash: string, amount: number): string {
    return `${hash}/${amount}/${dayjs().format(
      serverDateFormat,
    )}`;
  }

  /**
   * Creates an idempotency key that can be used to ensure the same payment is not entered twice,
   * based on a receipt book number.
   */
  public static createReceiptBookKey(receiptBookNumber?: string): string {
    if (receiptBookNumber) {
      return `${receiptBookNumber}`;
    } else {
      return generateUuid();
    }
  }

  /**
   * Creates an idempotency key that can be used to ensure the same Other payment is not entered twice.
   */
  public static createOtherKey(receiptBookNumber?: string): string {
    return Idempotency.createReceiptBookKey(receiptBookNumber);
  }

  /**
   * Creates an idempotency key that can be used to ensure the same Bank Transfer is not entered twice.
   */
  public static createBankTransferKey(receiptBookNumber?: string): string {
    return Idempotency.createReceiptBookKey(receiptBookNumber);
  }

  /**
   * Creates an idempotency key that can be used to ensure the same Cash payment is not entered twice.
   */
  public static createCashKey(receiptBookNumber?: string): string {
    return Idempotency.createReceiptBookKey(receiptBookNumber);
  }
}
