import { useState, useCallback, useEffect, useRef } from "react";

function useScript() {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const scriptRef = useRef<HTMLScriptElement | null>(null);
  const observerRef = useRef<MutationObserver | null>(null);

  const loadScript = useCallback((src: string, dataAttributes: Record<string, string | undefined> = {}) => {
    if (!src) return;

    setLoaded(false);
    setError(null);

    if (scriptRef.current && document.contains(scriptRef.current)) {
      scriptRef.current.remove();
    }

    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    const script = document.createElement("script");
    script.src = src;
    
    Object.entries(dataAttributes).forEach(([key, value]) => {
      if (value !== undefined) {
        script.setAttribute(`data-${key}`, value);
      }
    });

    script.onload = () => {
      removeAccessibilityAttributes(script);
      setLoaded(true);
    };
    
    script.onerror = (e) => {
      setError(e instanceof Error ? e : new Error("Script load error"));
    };

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "attributes") {
          removeAccessibilityAttributes(script);
        }
      });
    });

    function removeAccessibilityAttributes(element: HTMLElement) {
      const attributesToRemove = [
        "aria-hidden",
        "data-aria-hidden",
        "aria-disabled",
        "data-aria-disabled"
      ];
      
      attributesToRemove.forEach(attr => {
        if (element.hasAttribute(attr)) {
          element.removeAttribute(attr);
        }
      });
    }

    scriptRef.current = script;
    observerRef.current = observer;

    document.head.appendChild(script);
    
    observer.observe(script, { 
      attributes: true,
      attributeFilter: ["aria-hidden", "data-aria-hidden", "aria-disabled", "data-aria-disabled"]
    });
    
    removeAccessibilityAttributes(script);
    
  }, []);

  useEffect(() => {
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      
      if (scriptRef.current && document.contains(scriptRef.current)) {
        scriptRef.current.remove();
      }
    };
  }, []);

  return { loadScript, loaded, error };
}

export default useScript;