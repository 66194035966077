import React from "react";
import { Controller } from "react-hook-form";
import { Label, Button, Input, CountryDropdown } from "@n3oltd/n3o-ui-components";
import { Props } from "./Layout1";

 
export const AddressLayout2 =  React.forwardRef<HTMLInputElement, Props>(function AddressLayout2({isManualAddress, toggleAddressMode, control, inputClasses, formatMessage, addressSchema}: Props, ref) {

  const setInputRef = React.useCallback(
    (input: HTMLInputElement | null) => {
      if (typeof ref === "function") {
        ref(input);
      } else if (ref) {
        ref.current = input;
      }
    },
    [ref]
  );

  return <div className="space-y-2">
  <div className="flex justify-between items-center">
    <Label>{isManualAddress ? formatMessage('personalInfo.address') : formatMessage('personalInfo.findAddress')}</Label>
    <Button 
      type="button" 
      variant="link" 
      className="h-auto p-0 text-sm underline"
      onClick={toggleAddressMode}
    >
      {isManualAddress ? formatMessage('personalInfo.findAddress') : formatMessage('personalInfo.fillManually')}
    </Button>
  </div>
  {!isManualAddress ? (
    <Controller
      name="addressSearch"
      control={control}
      render={({ field }) => (
        <Input 
          {...field}
          ref={setInputRef}
          value={field.value as string}
          className={`${inputClasses}`}
          placeholder={formatMessage('personalInfo.addressSearch')}
          onChange={(e) => {
            field.onChange(e);
          }}
        />
      )}
    />
  ) : (
    <div className="space-y-[1px] relative">
      <div className="space-y-[1px] relative">
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <CountryDropdown
                {...field}
                defaultValue={field.value as string}
                className="rounded-b-none"
                onChange={country => {
                  field.onChange(country.alpha2);
                }}
              />
            )}
          />
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value as string}
                placeholder={addressSchema.locality.label}
                className={`${inputClasses} rounded-none border-t-0`}
              />
            )}
          />
          <Controller
            name="postCode"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value as string}
                placeholder={addressSchema.postalCode.label}
                className={`${inputClasses} rounded-none border-t-0`}
              />
            )}
          />
      <Controller
        name="addressLine1"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            value={field.value as string}
            placeholder={addressSchema.line1.label}
            className={`${inputClasses} rounded-none border-t-0`}
            />
          )}
        />
        <Controller
          name="addressLine2"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              value={field.value as string}
              placeholder={addressSchema.line2.label}
              className={`${inputClasses} rounded-none border-t-0`}
            />
          )}
        />
        <Controller
          name="addressLine3"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              value={field.value as string}
              placeholder={addressSchema.line3.label}
              className={`${inputClasses} rounded-none border-t-0`}
            />
          )}
        />        
        <div className="flex relative">        
          <Controller
            name="area"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value as string}
                placeholder={addressSchema.administrativeArea.label}
                className={`${inputClasses} rounded-t-none border-t-0`}
              />
            )}
          />
        </div>
      </div>
    </div>
  )}
</div>
})