import { useIntl } from 'react-intl'

type TranslationValues = Record<string, string | number | Date>

type NumberFormatOptions = Intl.NumberFormatOptions
type DateFormatOptions = Intl.DateTimeFormatOptions

export function useTranslation() {
    const intl = useIntl()

    return {
        formatMessage: (key: string, values?: TranslationValues) => 
            intl.formatMessage({ id: key }, values),
        
        formatNumber: (value: number, options?: NumberFormatOptions) =>
            intl.formatNumber(value, options),
            
        formatDate: (value: Date, options?: DateFormatOptions) =>
            intl.formatDate(value, options),
            
        formatCurrency: (value: number, currency: string) =>
            intl.formatNumber(value, { 
                style: 'currency', 
                currency 
            })
    }
} 