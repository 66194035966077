import { useCallback } from "react";
import { VerifyOtpReq, VerifyOtpRes } from "@n3oltd/karakoram.elements.sdk.login";

import { _loginClient } from "@api/common/clients/K2RestClient";
import { IApiResponse } from "@api/common/clients/ApiResponseModel";
import K2RestService from "@api/common/clients/K2RestService";

export type OTPType = "email" | "phone";

export const useVerifyOTP = (): {
  verifyOTP: (code: string, value: string, otpType: OTPType) => Promise<IApiResponse<VerifyOtpRes>>;
} => {

  const verifyOTP = useCallback(async (code: string, value: string, otpType: OTPType) => {
    const req: VerifyOtpReq = {
      code: code,
      channel: {
        ...(otpType === "email" ? { email: value } : { phoneNumber: value })
      }
    }

    const res = await K2RestService.toResponse(_loginClient!.verifyOtp(req));
    
    return res
  }, []);

  return {
    verifyOTP
  }
}