import React from 'react';
import { initializeGooglePlaces, loadGoogleMapsScript } from '@/utils/google/address';
import { PersonalInfoState } from '../types';

type UseAddressManagementProps = {
  infoState: PersonalInfoState;
  updateInfoState: (newState: Partial<PersonalInfoState>) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  setValue: (name: string, value: any) => void;
  apiKey: string | undefined;
};

export const useAddressManagement = ({
  infoState,
  updateInfoState,
  inputRef,
  setValue,
  apiKey
}: UseAddressManagementProps) => {
  React.useEffect(() => {
    loadGoogleMapsScript(apiKey || '',
      () => {
        if (inputRef.current && !infoState.isManualAddress) {
          initializeGooglePlaces(inputRef.current, address => {
            setValue('addressLine1', address.addressLine1)
            setValue('addressLine2', address.addressLine2 || '')
            setValue('city', address.city)
            setValue('country', address.country)
            setValue('postCode', address.postalCode)
            setValue('area', address.stateOrProvince || '')
            updateInfoState({isManualAddress: true})
          })
        }
      }, 
      () => {
        console.error('Failed to load Google Maps API');
        updateInfoState({ isManualAddress: true })
      });
  }, [inputRef.current, infoState.isManualAddress, updateInfoState]);

  const toggleAddressMode = () => {
    updateInfoState({ isManualAddress: !infoState.isManualAddress });
  };

  return {
    toggleAddressMode
  };
};