import React from 'react';
import { ArrowLeft, ArrowRight, Calendar } from 'lucide-react';
import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Label } from '@n3oltd/n3o-ui-components';
import { CheckoutRes, RegularGivingFrequency } from '@n3oltd/karakoram.checkout.sdk.checkout';
import { BooleanPaymentFlowRes, NMFlowCredentialReq, NMFlowPaymentReq, PaymentChannel } from '@n3oltd/karakoram.checkout.sdk.network-merchants';

import { Drawer } from './Drawer';
import { ServerError } from '@/common/components/Error';
import { LoadingOverlay } from '@/Loader';
import { PaymentError } from './payments/PaymentError';
import { useTranslation } from '@/i18n';
import useScript from '../hooks/useScript';
import { useApi } from '@/api/common/hooks/useApi';
import { _checkoutClient, _networkMerchantsClient } from '@/api/common/clients/K2RestClient';
import { CHECKOUT_SCOPES, NMI_SCRIPTS, PAYMENT_PROCESSORS } from '../consntants';
import Idempotency from '../utils/Idempotency';
import { convertToHex } from '@/utils/theme';
import { getNextStage } from "../utils/stageProgress";
import { CheckoutState, CollectJSPaymentToken, StepScreens } from '../types';

import theme from '../../theme.json'
import { getComplementaryColor } from '../utils/color';

interface CreditCardFormProps {
  state: CheckoutState;
  updateState: (newState: Partial<CheckoutState>) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
}

export default function CreditCard({
  goToNextStep,
  goToPreviousStep,
  updateState,
  state
}: CreditCardFormProps) {
  const [paymentError, setPaymentError] = React.useState<{
    error: any;
    show: boolean;
    processing: boolean
  }>({ error: "", show: false, processing: false });
  const [loading, setLoading] = React.useState(false);
  const [paymentDay, setPaymentDay] = React.useState( '');
  const [hideForm, setHideForm] = React.useState(false);
  
  const { formatMessage } = useTranslation();
  const {
    loadScript: loadGateway,
    loaded: isGatewayLoaded,
    error: gatewayLoadingError,
  } = useScript();
  const {
    loadScript: loadCollectJS,
    loaded: isCollectLoaded,
    error: collectLoadError,
  } = useScript();

  const {
    execute: processPayment,
    error: processPaymentError,
    isLoading,
  } = useApi<BooleanPaymentFlowRes>({
    async onSuccess(data: BooleanPaymentFlowRes) {
      setLoading(true);
      const res = await _checkoutClient!.getById(state.checkoutSession.id!);
      updateState({ checkoutSession: res });
      checkoutRevisionIdRef.current = res.revisionId;
      if (data.result) {
        setLoading(false);
        getNextStage(res.progress?.remainingStages, nextStage => {
                  updateState({currentDonationFlow: nextStage});
                });
        handleNextStep();
        window.themeConfig?.onCheckoutStepComplete && window.themeConfig.onCheckoutStepComplete(state.currentDonationFlow, res)
      }

      if(data.error){
        setLoading(false);
        if (state.currentDonationFlow === 'regularGiving') {
          setPaymentError({error: res.regularGiving?.credential?.errorMessage, show: res.regularGiving?.credential?.hasError || false, processing: true})
        }
        
        if (state.currentDonationFlow === 'scheduledGiving') {
          setPaymentError({error: res.scheduledGiving?.credential?.errorMessage, show: res.scheduledGiving?.credential?.hasError || false, processing: true})
        }
        
        if (state.currentDonationFlow === 'donation') {
          setPaymentError({error: res.donation?.payment?.errorMessage, show: res.donation?.payment?.hasError || false, processing: true})
        }
      }
    },
  });

  const {execute: _updateRegularGiving, error} = useApi<CheckoutRes>({
    async onSuccess(data: CheckoutRes) {
      updateState({ checkoutSession: data });
      checkoutRevisionIdRef.current = data.revisionId;
    },
  });

  const dayRef = React.useRef('0');
  const checkoutRevisionIdRef = React.useRef(state.checkoutSession.revisionId);

  const isDonation = state.currentDonationFlow === "donation";
  
  const payment = state.checkoutProfile?.payments.paymentMethods.find(p => p.processorId === PAYMENT_PROCESSORS.NMI)

  const updateRegularGiving = async () => {
    const rgReq = {
      firstCollectionDate: "",
      frequency: RegularGivingFrequency.Monthly,
      monthly: { collectionDay: dayRef.current },
    }

    return await _updateRegularGiving(_checkoutClient!.updateRegularGiving(checkoutRevisionIdRef.current!, rgReq));
  }

  const _processPayment = async (options: any, e: CollectJSPaymentToken, completeEvent: any) => {
    const donationReqPayload: NMFlowPaymentReq = {
      idempotencyKey: Idempotency.createCardKey(state.checkoutSession.id!, Number(options.amount)),
      value: state.checkoutSession.donation?.total,
      card: {
        cardBrand: e.card.type,
        channel: PaymentChannel.Ecommerce,
        token: e.token,
        threeDSecure: (() => {
          const payload = {
            cardholderAuth: completeEvent.cardHolderAuth,
            cavv: completeEvent.cavv,
            xId: completeEvent.xid,
            threeDSVersion: completeEvent.threeDsVersion
          };
        
          const filteredPayload = Object.fromEntries(
            Object.entries(payload).filter(([_, value]) => value !== undefined && value !== null && value !== '')
          );
        
          return Object.keys(filteredPayload).length > 0 ? filteredPayload : undefined;
        })()
      },
    };

    if (isDonation) {
      const donationReq = _networkMerchantsClient!.processPayment(
        payment!.id,
        state.checkoutSession.id!,
        CHECKOUT_SCOPES[state.currentDonationFlow],
        donationReqPayload
      );

      processPayment(donationReq);
    }

    if (!isDonation) {
      try {

        const amount = state.currentDonationFlow === "scheduledGiving" ? state.checkoutSession.scheduledGiving?.total : state.checkoutSession.regularGiving?.total;
        updateRegularGiving().then(() => {
          const credReqPayload: NMFlowCredentialReq = {
            setupPayment: {
              activeCheckOnly: state.currentDonationFlow === "scheduledGiving",
              value: amount,
            },
            ...donationReqPayload,
            
          };
  
          const credReq = _networkMerchantsClient!.processCredential(
            payment!.id,
            state.checkoutSession.id!,
            CHECKOUT_SCOPES[state.currentDonationFlow],
            credReqPayload
          );
  
          processPayment(credReq);
        });

      } catch (err: any) {
        if (err.status === 400) {
          setPaymentError({ show: true, error: err.errors[""][0], processing: false });
        }
        setLoading(false);
      }
    }
  }

  React.useEffect(() => {
    if (!payment?.additionalData?.tokenizationKey) {
      state.onError?.(formatMessage('creditCard.nmi.tokenizationKey.error'));
    }
    
    loadGateway(NMI_SCRIPTS.GATEWAY, {});
    loadCollectJS(NMI_SCRIPTS.COLLECT_JS, {
      "tokenization-key": payment?.additionalData?.tokenizationKey,
    });
  }, [
    loadGateway,
    loadCollectJS,
    formatMessage
  ]);
  
  React.useEffect(() => {
    if (!isGatewayLoaded || !isCollectLoaded) return;

    const gateway = window.Gateway.create(payment?.additionalData?.checkoutKey);
    const threeDS = gateway.get3DSecure();
    let threeDSecureInterface: any = null;
    let userBrowserJavaEnabled = String(false);
    try {
       userBrowserJavaEnabled = String(window.navigator.javaEnabled());
    } catch(e) {
        userBrowserJavaEnabled = String(false);
    }

    // Configure CollectJS once the component mounts
    window.CollectJS.configure({
      variant: "inline",
      styleSniffer: true,
      customCss: {
        "line-height": "1.5715",
        padding: "4px 11px",
        "border-radius": `${theme.borderRadius}`,
        border: `1px solid ${convertToHex(theme.colors.border)}`,
        height: `48px`,
      },
      focusCss: {
        border: `2px solid ${convertToHex(theme.colors.primary)}`,
      },
      invalidCss: {
        border: `1px solid ${convertToHex(theme.colors.destructive)}`,
      },
      callback: function (e: CollectJSPaymentToken) {
        try {
          setLoading(true);
          const options = { 
            paymentToken: e.token,
            country: state.personalInfo?.country,
            currency: state.checkoutSession.currency,
            firstName: state.personalInfo?.firstName,
            lastName: state.personalInfo?.lastName,
            browserJavaEnabled: userBrowserJavaEnabled,
            browserJavascriptEnabled: String(true),
            browserLanguage: window.navigator.language,
            browserColorDepth: String(window.screen.colorDepth),
            browserScreenHeight: String(window.screen.height),
            browserScreenWidth: String(window.screen.width),
            browserTimeZone: String(new Date().getTimezoneOffset()),
            deviceChannel: 'Browser',
            ...(() => {
              if (state.currentDonationFlow === "donation") {
                return {
                  amount: `${state.checkoutSession.donation?.total?.amount}`,
                };
              }
              if (state.currentDonationFlow === "regularGiving") {
                return {
                  amount: `${state.checkoutSession.regularGiving?.total?.amount}`,
                };
              }

              if (state.currentDonationFlow === "scheduledGiving") {
                return {
                  amount: `${state.checkoutSession.scheduledGiving?.total?.amount}`,
                };
              }
              return {};
            })(),
          };

          if (threeDSecureInterface) {
            threeDSecureInterface.unmount();
          }

          threeDSecureInterface = threeDS.createUI(options);
          threeDSecureInterface.start("#threeDSMountPoint");

          threeDSecureInterface.on("complete", async (completeEvent: any) => {
            if (threeDSecureInterface) {
              setHideForm(false);
              threeDSecureInterface.unmount();
            }

            await _processPayment(options, e, completeEvent);            
          });

          threeDSecureInterface.on("challenge", () => {
            setLoading(false);
            setHideForm(true);
          });

          threeDSecureInterface.on("failure", (failureEvent: any) => {
            if (threeDSecureInterface) {
              threeDSecureInterface.unmount();
              setHideForm(false);
            }

            setPaymentError({ error: failureEvent.message, show: true, processing: false });
            setLoading(false);
          });

          threeDSecureInterface.on('error', function (e: any) {
            setPaymentError({ error: e.message, show: true, processing: false });
            setLoading(false);
          });

          gateway.on("error", (err: any) => {
            console.error(err);
            setPaymentError({ error: err.message, show: true, processing: false });
            setLoading(false);
          });
        } catch (error) {
          setLoading(false);
        }
      },
      fields: {
        ccnumber: {
          placeholder: "CC Number",
          selector: "#ccnumber",
        },
        ccexp: {
          placeholder: "CC Expiration",
          selector: "#ccexp",
        },
        cvv: {
          placeholder: "CVV",
          selector: "#cvv",
        },
      },
    });

  }, [isCollectLoaded, isGatewayLoaded]);

  const handleChangePaymentMethod = () => {
    updateState({
      selectedPaymentMethod: null,
      currentStep: state.donationType == "oneOff" ? StepScreens.DonationDetails : StepScreens.DonationSummary,
    });
  };

  const handleNextStep = () => {
      goToNextStep();
  };

  const onSubmit = () => {
    if (!paymentDay && !isDonation) {
      setPaymentError({show: true, error: formatMessage('creditCard.paymentDay.required'), processing: false})
      return;
    }

    if (paymentDay) {
      setPaymentError({show: false, error: formatMessage('creditCard.paymentDay.required'), processing: false})
    }
    window.CollectJS.startPaymentRequest();
  };

  const scriptsLoaded = isCollectLoaded && isGatewayLoaded;
  const isRegularGiving = state.currentDonationFlow === "regularGiving";
  const isScheduledGiving = state.currentDonationFlow === "scheduledGiving";

  const renderDays = (() => {
    if (isRegularGiving) {
      return (payment?.collectionDaysOfMonth.map((day) => (
          <SelectItem key={day.dayOfMonth.id} value={day.dayOfMonth.id}>
            {day.dayOfMonth.name}
          </SelectItem>
        ))
      )
    }

    if (isScheduledGiving) {
      return (payment?.collectionDaysOfWeek.map((day) => (
        <SelectItem key={day.dayOfWeek.id} value={day.dayOfWeek.id}>
          {day.dayOfWeek.name}
        </SelectItem>
      ))
    )
    }

  })() || null;

   if (paymentError.show && paymentError.processing) {
     return (
       <PaymentError
         error={paymentError.error}
         onPaymentMethodChange={handleChangePaymentMethod}
         onPaymentRetry={() => setPaymentError({ show: false, error: "", processing: false })}
       />
     );
   }

  return (
    <>
      <LoadingOverlay
        loadingText={formatMessage("common.loading")}
        isLoading={!scriptsLoaded || loading || isLoading}
      />
      <Drawer.Content>
        <h1 className="text-2xl font-bold">
          {formatMessage("creditCard.title")}
        </h1>

        <p className="text-gray-600" style={{display: hideForm ?'none':'inherit'}}>
          {formatMessage("creditCard.description")}
        </p>

        <div className="space-y-4" style={{display: hideForm ?'none':'inherit'}}>
          <div className="space-y-2">
            <Label htmlFor="cardNumber">
              {formatMessage("creditCard.cardNumber")}
            </Label>
            <div className="relative">
              <div id="ccnumber"></div>
            </div>
          </div>

          <div className="flex gap-3">
            <div className="flex-1 space-y-2">
              <Label htmlFor="expiration">
                {formatMessage("creditCard.expiration")}
              </Label>
              <div id="ccexp"></div>
            </div>
            <div className="flex-1 space-y-2">
              <Label htmlFor="cvv">{formatMessage("creditCard.cvv")}</Label>
              <div id="cvv"></div>
            </div>
          </div>

          {!isDonation && (
            <div className="bg-primary/5 p-4 rounded-lg space-y-2">
              <Label>{formatMessage("creditCard.paymentCollection")}</Label>
              <div className="flex items-center gap-2">
                <p className="text-sm text-gray-600">
                  {formatMessage("creditCard.paymentCollection.description")}
                </p>
                <Select
                  onValueChange={(value) => {
                    dayRef.current = value;
                    setPaymentDay(value)
                    setPaymentError({show: false, error: '', processing: false})
                  }}
                >
                  <SelectTrigger className="h-12 w-40">
                    <Calendar className="mr-2 h-4 w-4" />
                    <SelectValue/>
                  </SelectTrigger>
                  <SelectContent>
                    {renderDays}
                  </SelectContent>
                </Select>
              </div>
            </div>
          )}
        </div>


      <div id="threeDSMountPoint" className='w-full' style={{background: getComplementaryColor(theme.colors.background)}}></div>

        {gatewayLoadingError && <ServerError error={{message: gatewayLoadingError.message}} />}
        {collectLoadError && <ServerError error={{message: collectLoadError.message}} />}
        {paymentError.show && <ServerError error={{message: paymentError.error}} />}
        {processPaymentError &&  <ServerError error={processPaymentError}/>}
        {error &&  <ServerError error={error}/>}
        
        
      </Drawer.Content>
      <Drawer.Footer>
        <Button
          variant="outline"
          className="w-full"
          onClick={handleChangePaymentMethod}
        >
          {formatMessage("common.button.changePaymentMethod")}
        </Button>

        <div className="flex gap-2">
          <Button variant="outline" size="lg" onClick={goToPreviousStep}>
            <ArrowLeft className="mr-2 h-4 w-4" />
          </Button>
          <Button size="lg" className="flex-1" type="submit" onClick={onSubmit} disabled={hideForm}>
            {formatMessage("creditCard.button.makePayment")}
            <ArrowRight className="ml-2 h-4 w-4" />
          </Button>
        </div>
      </Drawer.Footer>
    </>
  );
}
