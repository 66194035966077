import React from "react";
import { ArrowLeft, CircleCheckIcon } from "lucide-react";
import { BooleanPaymentFlowRes, DigitalWalletType, NMFlowPaymentReq, PaymentChannel } from "@n3oltd/karakoram.checkout.sdk.network-merchants";
import { Button, DonationSummaryCard, PaymentOption } from "@n3oltd/n3o-ui-components";
import { MoneyRes } from "@n3oltd/karakoram.checkout.sdk.checkout";

import { Drawer } from "./Drawer";
import { ServerError } from "@/common/components/Error";
import { PaymentButtons } from "./payments/PaymentButtons";
import { LoadingOverlay } from "@/Loader";
import { useTranslation } from "@/i18n";
import { useApi } from "@/api/common/hooks/useApi";
import { _checkoutClient, _networkMerchantsClient } from "@/api/common/clients/K2RestClient";
import { CHECKOUT_SCOPES, PAYMENT_PROCESSORS } from "../consntants";
import Idempotency from "../utils/Idempotency";
import { AGConfig } from "./payments/AppleGooglePay";
import { isAppleOrGooglePay } from "../utils/payments";
import { CheckoutState, CollectJSPaymentToken, DonationKeys, PaymentType } from "../types";

type Props = {
  state: CheckoutState;
  updateState: (newState: Partial<CheckoutState>) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
};

const donationKeys: DonationKeys[] = ['donation', 'regularGiving', 'scheduledGiving'];

export function DonationSummary({ goToNextStep, goToPreviousStep, updateState, state }: Props) {
  const { formatMessage, formatCurrency } = useTranslation();
  const [loading, setLoading] = React.useState(false);
      const [paymentError, setPaymentError] = React.useState<{
        error: any;
        show: boolean;
      }>({show: false, error: ''});

  const [paymentMethodType, setPaymentMethodType] = React.useState<PaymentType | null>(null);

  const {
        execute: processPayment,
        error: paymentProcessingError,
        isLoading: isProcessingPayment,
      } = useApi<BooleanPaymentFlowRes>({
        async onSuccess(data: BooleanPaymentFlowRes) {
          setLoading(true);
          const res = await _checkoutClient!.getById(state.checkoutSession.id!);
          updateState({ checkoutSession: res });
          if (data.result) {
            setLoading(false);
            paymentMethodType && handlePaymentClick(paymentMethodType);
            goToNextStep();
          }
    
          if(data.error){
            setLoading(false);          
            setPaymentError({
              error: res.donation?.payment?.errorMessage,
              show: res.donation?.payment?.hasError || false,
            });
          }
        },
      });

  const processDonationPayment = (event: CollectJSPaymentToken) => {

    updateState({
      selectedPaymentMethod: event.tokenType as PaymentType,
    });

    const donationReqPayload: NMFlowPaymentReq = {
      idempotencyKey: Idempotency.createCardKey(state.checkoutSession.id!, state.checkoutSession.donation?.total?.amount!),
      card: {
        token: event.token,
        walletType: event.tokenType as DigitalWalletType,
        cardBrand: event.card.type,
        channel: PaymentChannel.Ecommerce,
      },
      value: state.checkoutSession.donation?.total,
    };
      
                  
    const donationReq = _networkMerchantsClient!.processPayment(
      payment!.id,
      state.checkoutSession.id!,
      CHECKOUT_SCOPES[state.currentDonationFlow],
      donationReqPayload
    );
      
    processPayment(donationReq);
  
  }

  const handleNextStep = (paymentType: PaymentType) => {
    updateState({
      selectedPaymentMethod: paymentType,
    });

    if (isAppleOrGooglePay(paymentType)) {
      return;
    }

    goToNextStep();
  };

  const handlePaymentClick = (selected: PaymentType | DonationKeys) => {
      if (Object.values(PaymentType).includes(selected as PaymentType)) {
        handleNextStep(selected as PaymentType);
  
        return;
      }
  }

  const paidDonations = donationKeys
    .map((key) => ({key, value: state.checkoutSession[key]}))
    .filter((item) => item?.value?.isComplete);

  const donationItem = state.checkoutSession[state.currentDonationFlow];
  const awaitingPayment = !donationItem?.isComplete && donationItem?.isRequired ? donationItem : null;
  const payment = state.checkoutProfile?.payments.paymentMethods.find(p => p.processorId === PAYMENT_PROCESSORS.NMI);

  const formatAmount = (donationKey: DonationKeys, total: MoneyRes | undefined) => {
    if (donationKey == 'regularGiving') {
      return `${formatCurrency(total?.amount!, total?.currency!)} / ${formatMessage('common.month')}`
    }

    return formatCurrency(total?.amount!, total?.currency!);
  };

  return (
    <>
      <LoadingOverlay
        loadingText=""
        isLoading={loading || isProcessingPayment}
      />
      <Drawer.Content>
        <h2>{formatMessage(paidDonations.length > 0 ? "donationSummary.continuePayment" : "donationSummary.title")}</h2>

        {paidDonations.length > 0 && (
          <DonationSummaryCard>
            {paidDonations.map((item, index) => (
              <React.Fragment key={index}>
                <DonationSummaryCard.Title>
                  {formatMessage("common.paid")}
                </DonationSummaryCard.Title>
                <DonationSummaryCard.Content key={index}>
                  <DonationSummaryCard.Description>
                    <span className="inline-flex items-center gap-1">
                      <CircleCheckIcon className="text-green-600" />
                      {formatMessage(`common.${item.key}`)}
                    </span>
                  </DonationSummaryCard.Description>
                  <DonationSummaryCard.Amount
                    value={formatAmount(item.key, item.value?.total)}
                  />
                </DonationSummaryCard.Content>
              </React.Fragment>
            ))}
          </DonationSummaryCard>
        )}

        {awaitingPayment && (
          <DonationSummaryCard>
            <DonationSummaryCard.Title>
              {formatMessage("common.awaitingPayment")}
            </DonationSummaryCard.Title>
            <DonationSummaryCard.Content>
              <DonationSummaryCard.Description>
                {formatMessage(`common.${state.currentDonationFlow}`)}
              </DonationSummaryCard.Description>
              <DonationSummaryCard.Amount
                value={formatCurrency(
                  awaitingPayment?.total?.amount!,
                  awaitingPayment?.total?.currency!
                )}
              />
            </DonationSummaryCard.Content>
          </DonationSummaryCard>
        )}

        {paymentError.show && (<ServerError error={{message: paymentError.error}} />)}
        {paymentProcessingError && (<ServerError error={{message: paymentProcessingError}} />)}

      </Drawer.Content>
      <Drawer.Footer>
        <PaymentButtons
          donationType={state.donationType}
          checkoutProfile={state.checkoutProfile}
          checkoutSession={state.checkoutSession}
          handleClick={handlePaymentClick}
          skipCombined={true}
          currentFlow={state.currentDonationFlow}
          isRegularGivingPaid={
            state.checkoutSession.regularGiving?.isComplete || false
          }
          isDoantionPaid={state.checkoutSession.donation?.isComplete || false}
          config={
            {
              isGoogleApplePayDisabled: state.checkoutSession.donation?.isComplete,
              country: state.personalInfo?.country || "",
              currency: state.checkoutSession.currency,
              checkoutKey: payment?.additionalData?.checkoutKey,
              token: payment?.additionalData?.tokenizationKey,
              onLoading: (l) => setLoading(l),
              onPaymentComplete(payload) {
                setPaymentMethodType(payload.tokenType as PaymentType);
                setPaymentError({ show: false, error: "" });
                processDonationPayment(payload);
              },
              onPaymentFailure(payload) {
                setPaymentError({ show: false, error: payload });
              },
              ...(() => {
                if (state.currentDonationFlow === "donation") {
                  return {
                    price: `${state.checkoutSession.donation?.total?.amount}`,
                  };
                }
                return {};
              })(),
            } as AGConfig
          }
        />
        <PaymentOption.Divider />
        <Button variant="outline" onClick={goToPreviousStep}>
          <ArrowLeft className="h-4 w-4 ml-2" />
          {formatMessage("common.button.back")}
        </Button>
      </Drawer.Footer>
    </>
  );
}