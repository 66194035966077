import { N3O_ATTRIBTION_COOKIE_KEY } from "@/api/common/constants/cookie";
import { CheckoutReq, AutomaticAttributionParametersReq } from "@n3oltd/karakoram.checkout.sdk.checkout";

interface AttributionData {
  attribution: {
    [key: string]: Array<{
      option: string;
      percentage: number;
    }>;
  };
}

interface MappedAttribution {
  [key: string]: string[];
}


export function getAttributionCookie(): AutomaticAttributionParametersReq | {} {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(`${N3O_ATTRIBTION_COOKIE_KEY}=`))
    ?.split('=')[1];

  if (!cookieValue) return {}

  try {
    const parsedData = JSON.parse(decodeURIComponent(cookieValue)) as AttributionData;
    return mapAttributionData(parsedData);
  } catch (error) {
    console.error('Error parsing attribution cookie:', error);
    return {};
  }
}

function mapAttributionData(data: AttributionData): CheckoutReq {
  const mappedData: MappedAttribution = {};
  
  Object.entries(data.attribution).forEach(([key, value]) => {
    const dimensionKey = `dimension${Number(key.substring(1)) + 1}`;
    mappedData[dimensionKey] = value.map(v => v.option);
  });

  return {
    attribution: Object.entries(mappedData).reduce((acc, [key, options]) => {
      acc[key as keyof AutomaticAttributionParametersReq] = { options };
      return acc;
    }, {} as AutomaticAttributionParametersReq)
  };
}
