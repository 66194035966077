import { EligibleBank } from "@n3oltd/karakoram.checkout.sdk.payit";
import { cn } from "@n3oltd/n3o-ui-components";
import { Check } from "lucide-react";
import { useTranslation } from "@/i18n";



type Props = {
  banks: EligibleBank[],
  onBankSelect: (id: string | undefined) => void
}

export function BankList({banks, onBankSelect}: Props) {
  const {formatMessage} = useTranslation()

  return (
    <div className="w-full max-w-3xl mx-auto space-y-2">
      {banks.map((bank) => (
        <div
          onClick={() => onBankSelect(bank.id)}
          key={bank.id}
          className={cn(
            'flex items-center justify-between p-4 rounded-lg border',
            'hover:bg-accent transition-colors duration-200',
            'cursor-pointer',
            bank.temporarilyUnavailable ? 'opacity-50' : 'opacity-100'
          )}
        >
          <div className="flex items-center space-x-4">
            <div className="relative w-12 h-12 overflow-hidden rounded-full">
              <img
                src={bank.logoUrl}
                alt={bank.name}
                className="object-cover p-2 bg-accent"
              />
            </div>
            <span className="font-medium">{bank.name}</span>
          </div>
          <div className="flex items-center">
            {bank.temporarilyUnavailable ? (
              <span className="text-sm text-muted-foreground">{formatMessage('common.unavailable')}</span>
            ) : (
              <span className="text-sm text-green-600 dark:text-green-400 flex items-center">
                <Check className="w-4 h-4 mr-1" />
                {formatMessage('common.available')}
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
