import { CheckoutRes, CheckoutStage } from '@n3oltd/karakoram.checkout.sdk.checkout';
import { AccountType, ElementsPreferenceSelectionRes } from '@n3oltd/karakoram.checkout.sdk.checkout';
import { Preference } from '@n3oltd/k2.accounts.sdk.public';
import { useApi } from '@/api/common/hooks/useApi';
import { CheckoutState, DonationKeys, DonationTypeEnum, CheckoutInfo, PersonalInfo, StepScreens } from '../types';

type UseCheckoutSessionProps = {
  updateState: (newState: Partial<CheckoutState>) => void;
  hasPayitHash?: boolean
};

export const useCheckoutSession = ({ updateState, hasPayitHash }: UseCheckoutSessionProps) => {

  const { execute, isLoading: isCheckoutSessionLoading } = useApi({
    onSuccess(data: CheckoutRes) {
      if (!data.account) {
        const donationType = getDonationType(data);
        updateState({
          checkoutSession: data,
          donationType: donationType.donationType,
          currentDonationFlow: donationType.flow
        });
        return;
      }

      const { personalInfo, checkoutInfo } = mapAccountData(data.account);

      const donationType = getDonationType(data);
      
      updateState({
        checkoutSession: data,
        donationType: donationType.donationType,
        currentDonationFlow: donationType.flow,
        personalInfo,
        checkoutInfo
      });

      if (data.progress?.remainingStages?.includes(CheckoutStage.Account) &&  hasPayitHash) {
        updateState({
          currentStep: StepScreens.PersonalInfo
        })
      }

      if (hasPayitHash) {
        updateState({
          currentStep: StepScreens.BankTransfer
        })
      }

    },
  });

  return {
    execute,
    isLoading: isCheckoutSessionLoading,
    getDonationType
  };
};

export function getDonationType(data: CheckoutRes): { donationType: DonationTypeEnum, flow: DonationKeys } {
  const hasDonation = !!data.donation?.cartItems?.length;
  const hasRegularGiving = !!data.regularGiving?.cartItems?.length;
  const hasScheduleGving = !!data.scheduledGiving?.cartItems?.length;

  if (hasDonation && hasRegularGiving && hasScheduleGving) {
    return { donationType: DonationTypeEnum.OneOffAndRegular, flow: 'donation' };
  }

  if (hasDonation && hasRegularGiving) {
    return { donationType: DonationTypeEnum.OneOffAndRegular, flow: 'donation' };
  }

  if (hasRegularGiving) {
    return { donationType: DonationTypeEnum.Regular, flow: 'regularGiving' };
  }

  if (hasScheduleGving) {
    return { donationType: DonationTypeEnum.Scheduled, flow: 'scheduledGiving' };
  }

  return { donationType: DonationTypeEnum.OneOff, flow: 'donation' };
}



export const mapAccountData = (account: any) => {
  const {
    address,
    email,
    preferences,
    taxStatus,
    organization,
    telephone,
    individual,
  } = account || {};

  // Map personal information
  const personalInfo: Partial<PersonalInfo> = {
    email: email?.address || "",
    phone: telephone?.number,
    title: individual?.name?.title || organization?.contact?.title || "",
    firstName:
      individual?.name?.firstName || organization?.contact?.firstName || "",
    lastName:
      individual?.name?.lastName || organization?.contact?.lastName || "",
    addressLine1: address?.line1 || "",
    addressLine2: address?.line2 || "",
    city: address?.locality || "",
    area: address?.administrativeArea || "",
    country: address?.country || "",
    postCode: address?.postalCode || "",
    phoneCountry: telephone?.country,
    terms: false,
  };

  // Map preferences
  const mappedPrefs = preferences?.selections?.reduce(
    (acc: Record<string, any>, curr: ElementsPreferenceSelectionRes) => {
      if (curr.channel && curr.response !== Preference.NoResponse) {
        acc[curr.channel] = curr.response == Preference.OptIn;
      }

      return acc;
    },
    {}
  );

  // Map checkout information
  const checkoutInfo: Partial<CheckoutInfo> = {
    accountType: organization?.name
      ? AccountType.Organization
      : AccountType.Individual,
    giftAidSelection:
      taxStatus?.canClaim === true
        ? "boost"
        : taxStatus?.canClaim === false
        ? "no"
        : "unsure",
    prefs: mappedPrefs || {},
    isOrgSelected: !!organization?.name || false,
    org: organization?.name
      ? {
          name: organization.name,
          contact: organization.contact,
          type: organization.type,
        }
      : {},
  };

  return { personalInfo, checkoutInfo };
};
