
export const giftAids = [{
  schemeId: 'unitedKingdom',
  calculateAmount: (amount: number) => amount * 0.25,
  description: {
    line1: 'giftAid.description.line1',
    line2: 'giftAid.description.line2',
  },
  link: {
    text: 'giftAid.link',
    url: '#'
  },
  logoUrl: 'https://upload.wikimedia.org/wikipedia/en/9/9f/Gift_Aid_UK_Logo.svg',
}];

export const giftAidClaimOptions = {
  boost: 'boost',
  no: 'no',
  unsure: 'unsure',
  getValue(value: string){
    if (value === this.boost) {
      return true
    }

    if (value === this.no) {
      return false
    }

    if (value === this.unsure) {
      return null
    }

    return undefined;
  } 
}