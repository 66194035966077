import { ArrowRight, CirclePlus } from 'lucide-react';
import { Button } from "@n3oltd/n3o-ui-components";
import { useTranslation } from '@/i18n';
import { Drawer } from '../Drawer';
import { LookupService } from '@/api/common/utils/Lookup';
import { DonorRecord } from '@/checkout/types';

interface AccountSelectionProps {
  accounts: DonorRecord[];
  onAccountSelected: (account: DonorRecord) => void;
  onSkip: () => void;
}

export function AccountSelection({accounts, onAccountSelected, onSkip}: AccountSelectionProps) {
  const { formatMessage } = useTranslation();

  return (
    <Drawer.Content>
      <div className="flex flex-col p-3 gap-3 border border-gray-200 rounded-lg">
        <div>
          <h3 className="text-xs tracking-wider uppercase font-semibold text-gray-600">
            {formatMessage("account.selectDetails")}
          </h3>
        </div>

        <div className="flex flex-col gap-3">
          <div className="border-t border-orange-100 bg-orange-50 p-4">
            <p className="text-sm text-orange-800">
              {formatMessage("account.selectDetails.description")}
            </p>
          </div>
          {accounts.map((donor, index) => (
            <div
              key={index}
              onClick={() => onAccountSelected(donor)}
              className="flex group hover:border-primary flex-col sm:flex-row items-stretch justify-between p-4 bg-white rounded-lg border border-gray-200 hover:bg-gray-50 cursor-pointer transition-colors duration-200"
            >
              <div className="space-y-1">
                <p className="text-base font-medium">{donor.name}</p>
                {donor.phone && (
                  <p className="text-sm text-gray-600">{donor.phone?.number}</p>
                )}
                {donor.address.line1 && <p className="text-xs text-gray-600">{donor.address.line1}</p>}
                {donor.address.line2 && <p className="text-xs text-gray-600">{donor.address.line2}</p>}
                {donor.address.line3 && <p className="text-xs text-gray-600">{donor.address.line3}</p>}
                {donor.address.locality && <p className="text-xs text-gray-600">{donor.address.locality}</p>}
                {donor.address.administrativeArea && <p className="text-xs text-gray-600">{donor.address.administrativeArea}</p>}
                {donor.address.postalCode && <p className="text-xs text-gray-600">{donor.address.postalCode}</p>}
                {donor.address.country && <p className="text-xs text-gray-600">{LookupService.getCountry(donor.address.country)?.Name}</p>}
              </div>
              <div className="flex flex-col justify-between mt-2 pt-2 border-t sm:pt-0 sm:mt-0 sm:pl-4 sm:border-t-0 sm:border-l border-gray-200 sm:max-w-[160px]">
                <p className="text-xs font-medium">
                  {formatMessage("account.reference", {
                    reference: donor.reference,
                  })}
                </p>
                <div className="relative">
                  <ArrowRight className="block absolute h-4 w-4 text-gray-400 group-hover:text-primary top-0 right-0" />
                </div>
                <div className="space-y-1">
                  <p className="text-xs text-gray-500">
                    {formatMessage("account.created", {
                      created: donor.created,
                    })}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
          <Button
            size="lg"
            variant="outline"
            className="w-full"
            onClick={onSkip}
          >
            <CirclePlus className="h-4 w-4"/>{formatMessage("personalInfo.accountSkip")}
          </Button>
    </Drawer.Content>
  );
}
