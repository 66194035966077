import React, {forwardRef} from 'react';
import { CreditCard, Landmark, LucideProps } from 'lucide-react';
import PayitIcon  from './Payit.svg'


const PayIt = forwardRef<SVGSVGElement, Omit<LucideProps, "ref">>(
  (_, ref) => {
    return <img src={PayitIcon} alt="Icon" className="h-4" ref={ref as any}/>;
  }
);
export const ICONS: Record<string, React.ForwardRefExoticComponent<Omit<LucideProps, "ref"> & React.RefAttributes<SVGSVGElement>>> = {
  'bank.svg': Landmark,
  'payit.svg': PayIt,
  'card.svg': CreditCard,
}