import React from "react";
import { ControllerRenderProps, UseFormSetValue } from "react-hook-form";
import { Country, CountryDropdown, PhoneInput } from "@n3oltd/n3o-ui-components";

type Props = {
  country?: string | undefined,
  onCountryChange: (alphaCdoe2: string | undefined) => void
  onBlur: () => void,
  setValue: UseFormSetValue<{
    [x: string]: string | boolean;
    [x: number]: string | boolean;
  }>
  field: ControllerRenderProps<{
    [x: string]: string | boolean;
    [x: number]: string | boolean;
}, "phone">
}

export const PhoneWithCountry = React.forwardRef(({country, field, setValue, onBlur, onCountryChange}: Props, _) => {
  const [selectedCountry, setSelectedCountry] = React.useState<Country | null>(null);

  return (
    <div className="flex items-center w-full">
      <CountryDropdown
        onChange={(country) => {
          setSelectedCountry(country);

          const countryCode = country.countryCallingCodes[0];
          const formattedCode = countryCode.startsWith("+")
            ? countryCode
            : `+${countryCode}`;
          setValue("phone", formattedCode);
        }}
        defaultValue={selectedCountry?.alpha2 || country}
        inline
      />
      <PhoneInput
        {...field}
        value={field.value as string}
        className='w-full'
        defaultCountry={selectedCountry?.alpha2}
        onCountryChange={(country) => {
          setSelectedCountry(country as Country);
          onCountryChange(country?.alpha2)
        }}
        onBlur={onBlur}
        inline
      />
    </div>
  );
});