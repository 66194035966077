type HexColor = `#${string}`;
type HslColor = { h: number; s: number; l: number };

function isHexColor(color: string): color is HexColor {
  return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(color);
}

function isHslString(color: string): boolean {
  const regex = /^(\d{1,3})\s+(\d{1,3})%\s+(\d{1,3})%$/;
  return regex.test(color);
}

function parseHslString(hslString: string): HslColor | undefined {
  const regex = /^(\d{1,3})\s+(\d{1,3})%\s+(\d{1,3})%$/;
  const match = hslString.match(regex);
  if (!match) {
    return
  }

  const h = parseInt(match[1], 10);
  const s = parseInt(match[2], 10);
  const l = parseInt(match[3], 10);

  if (h < 0 || h > 360 || s < 0 || s > 100 || l < 0 || l > 100) {
    return
  }

  return {
    h: h / 360,
    s: s / 100, 
    l: l / 100, 
  };
}

export function getComplementaryColor(color: HexColor | HslColor | string): HexColor | undefined {
  function hexToRgb(hex: string) {
    hex = hex.replace('#', '');
    if (hex.length !== 6) {
      return 
    }

    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  }

  function rgbToHsl(r: number, g: number, b: number) {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 0,
      s = 0,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // Achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }
    return { h, s, l };
  }

  function hslToRgb(h: number, s: number, l: number) {
    let r: number, g: number, b: number;
    if (s === 0) {
      r = g = b = l; // Achromatic
    } else {
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hueToRgb(p, q, h + 1 / 3);
      g = hueToRgb(p, q, h);
      b = hueToRgb(p, q, h - 1 / 3);
    }
    return { r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255) };
  }

  function hueToRgb(p: number, q: number, t: number) {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  }

  let hsl: { h: number; s: number; l: number } | undefined;
  if (isHexColor(color as string)) {
    const rgb = hexToRgb(color as HexColor);

    if (!rgb) {
      return
    }

    hsl = rgbToHsl(rgb.r, rgb.g, rgb.b);
  } else if (typeof color === 'string' && isHslString(color)) {
    hsl = parseHslString(color);
  } else if (typeof color === 'object' && 'h' in color && 's' in color && 'l' in color) {
    hsl = color as HslColor;
  } else {
    return
  }

  if (!hsl) {
    return
  }

  hsl.h = (hsl.h + 0.5) % 1;

  // Convert back to RGB and then to Hex
  const complementaryRgb = hslToRgb(hsl.h, hsl.s, hsl.l);
  const complementaryHex = `#${complementaryRgb.r.toString(16).padStart(2, '0')}${complementaryRgb.g
    .toString(16)
    .padStart(2, '0')}${complementaryRgb.b.toString(16).padStart(2, '0')}`;

  return complementaryHex as HexColor;
}
