import React from 'react';
import { LucideInfo } from 'lucide-react';
import { CheckoutRes } from '@n3oltd/karakoram.checkout.sdk.checkout';
import { Button } from '@n3oltd/n3o-ui-components';

import { useTranslation } from '@/i18n';
import { AGConfig, AppleGooglePay } from './AppleGooglePay';
import { PAYMENT_PROCESSORS } from '@/checkout/consntants';
import { ICONS } from './icons';
import { CheckoutProfileConfig, DonationKeys, PaymentType } from '@/checkout/types';

type Props = {
  donationType: string;
  checkoutSession: CheckoutRes;
  checkoutProfile: CheckoutProfileConfig | null | undefined 
  handleClick: (paymentType: PaymentType | DonationKeys) => void
  skipCombined?: boolean,
  config: AGConfig,
  currentFlow: DonationKeys,
  isDoantionPaid: boolean,
  isRegularGivingPaid: boolean
}

export const PaymentButtons =  ({
  donationType,
  checkoutSession,
  checkoutProfile,
  handleClick,
  skipCombined,
  config,
  currentFlow,
  isDoantionPaid,
  isRegularGivingPaid
}: Props) => {
  const {formatMessage, formatCurrency} = useTranslation();
  const isCombinedDonation = donationType === 'oneOffAndRegular';

  let paymentMethods = checkoutProfile?.payments?.paymentMethods?.filter(p => p.allowedCurrencies.includes(config.currency)) || [];
  paymentMethods = [...paymentMethods].sort((a, b) => a.order - b.order);

  const appleGoogleMethods = paymentMethods.filter((method) => method.supportsApplePay || method.supportsGooglePay);

  if (isCombinedDonation && !skipCombined) {
    return  <>
        <Button
          size="lg"
          className="w-full border h-12 flex items-center justify-center"
          onClick={() => handleClick('donation')}
          disabled={isDoantionPaid}
        >
          {formatMessage('payment.button.singleDonation')}
          <span>{formatCurrency(checkoutSession.donation?.total?.amount || 0, checkoutSession.currency || '')}</span>
        </Button>

        <Button
          size="lg"
          className="w-full border h-12 flex items-center justify-center"
          onClick={() => handleClick('regularGiving')}
          disabled={isRegularGivingPaid}
        >
          {formatMessage('payment.button.regularPayment')}
          <span>{formatCurrency(checkoutSession.regularGiving?.total?.amount || 0, checkoutSession.currency || '')}/{formatMessage('common.month')}</span>
        </Button>
      </>
  }

  if (currentFlow === 'regularGiving' || currentFlow === 'scheduledGiving') {
    return <>
        {paymentMethods.map(({processorId, displayName, iconId}) => {
          const Icon = ICONS[iconId];
            return (
              <React.Fragment key={processorId}>
                {processorId === PAYMENT_PROCESSORS.DirectDebitUK && (
                  <Button
                    size="lg"
                    className="w-full border h-12 flex items-center justify-center"
                    onClick={() => handleClick(PaymentType.DirectDebit)}
                    disabled={isRegularGivingPaid}
                  >
                    {Icon ? <Icon /> : null} {displayName}
                  </Button>
                )}

                {processorId === PAYMENT_PROCESSORS.NMI && (
                  <Button
                    size="lg"
                    className="w-full border h-12 flex items-center justify-center"
                    onClick={() => handleClick(PaymentType.Credit)}
                    disabled={isRegularGivingPaid}
                  >
                    {Icon ? <Icon /> : null} {displayName}
                  </Button>
                )}
              </React.Fragment>
            );
      })}
    </>
  }

  if (currentFlow === 'donation') {
    const payit = paymentMethods.find((method) => method.processorId === PAYMENT_PROCESSORS.Payit);
    return (
      <>
        {appleGoogleMethods.length > 0 ? (
          <AppleGooglePay
            handleClick={handleClick}
            methods={appleGoogleMethods}
            config={config}
          />
        ) : null}
        {paymentMethods.map(({ processorId, displayName, iconId }) => {
          const Icon = ICONS[iconId];
          
          return (
            <React.Fragment key={processorId}>
              {processorId === PAYMENT_PROCESSORS.Payit && (
                <Button
                  size="lg"
                  className="w-full border h-12 flex items-center justify-center"
                  onClick={() => handleClick(PaymentType.Instant)}
                  disabled={isDoantionPaid}
                >
                  {Icon ? <Icon /> : null} {displayName}
                </Button>
              )}

              {processorId === PAYMENT_PROCESSORS.NMI && (
                <Button
                  size="lg"
                  className="w-full border h-12 flex items-center justify-center"
                  onClick={() => handleClick(PaymentType.Credit)}
                  disabled={isDoantionPaid}
                >
                  {Icon ? <Icon /> : null} {displayName}
                </Button>
              )}
            </React.Fragment>
          );
        })}
        {payit && (
                <p className="flex text-sm font-medium text-gray-600 ">
                  <LucideInfo className="h-4 mr-1 mt-[1.7px]" /> {payit.description}
                </p>
              )}
      </>
    );
  }

  return null;
}

