import { AlertCircle } from "lucide-react";

type AlertProps = {
  title?: string;
  message: string;
};

export function WarningAlert({ title, message }: AlertProps) {
  return (
    <div className="bg-amber-50 border border-amber-100 rounded-md p-4 mb-6">
      <div className="flex items-start">
        <AlertCircle className="h-5 w-5 text-amber-500 mt-0.5 mr-3 flex-shrink-0" />
        <div>
          {title && <h3 className="text-sm font-medium text-amber-800">{title}</h3>}
          <div className="mt-1 text-sm text-amber-700">
            <p>{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
}