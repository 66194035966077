import { CheckoutStage } from '@n3oltd/karakoram.checkout.sdk.checkout';
import { isAppleOrGooglePay } from './utils/payments';
import { CheckoutState, DonationType, PaymentType, Step, StepScreens } from './types';
  
type NavigationRule = {
  next?: Step | ((state: CheckoutState) => Step);
  back?: Step | ((state: CheckoutState) => Step);
};

type NavigationSchema = {
  [key in DonationType]: {
    [key in Step]?: NavigationRule;
  };
};

const requiresPersonalInfo = (state: CheckoutState): boolean => {
  if (!state.selectedPaymentMethod) {
    return false;
  }

  const isAccountDetailsRequired = !['applePay', 'googlePay', 'paypal'].includes(state.selectedPaymentMethod)
  
  if (isAccountDetailsRequired && state.checkoutSession.progress!.remainingStages!.includes(CheckoutStage.Account)) {
    return true;
  }

  return false;
};


const baseSchema: NavigationSchema = {
  oneOff: {
    [StepScreens.Basket]: { 
      next: StepScreens.DonationDetails
    },
    [StepScreens.DonationDetails]: {
      next: (state) => {
        if (requiresPersonalInfo(state!)) {
          return StepScreens.PersonalInfo;
        }

        if (state.selectedPaymentMethod === PaymentType.Instant) {
          return StepScreens.BankTransfer;
        }
        
        if (state.selectedPaymentMethod === PaymentType.Credit) {
          return StepScreens.CreditCard;
        }

        if (isAppleOrGooglePay(state.selectedPaymentMethod) 
          && !state.checkoutSession.account?.isComplete) {
          
            return StepScreens.PersonalInfo;
        }
        
        return StepScreens.ThankYou
      },
      back: StepScreens.Basket,
    },
    [StepScreens.PersonalInfo]: { 
      next: state => {
        if (state.selectedPaymentMethod === PaymentType.Credit) {
          return StepScreens.CreditCard;
        }

        if (state.selectedPaymentMethod === PaymentType.Instant) {
          return StepScreens.BankTransfer;
        }

        if ((isAppleOrGooglePay(state.selectedPaymentMethod)) 
          && state.checkoutSession.account?.isComplete) {
          
            return StepScreens.ThankYou
        }

        return StepScreens.PersonalInfo;
      },
      back: StepScreens.DonationDetails 
    },
    [StepScreens.CreditCard]: { next: StepScreens.ThankYou, back: StepScreens.PersonalInfo },
    [StepScreens.BankTransfer]: { next: StepScreens.ThankYou, back: StepScreens.PersonalInfo },
    [StepScreens.ThankYou]: { back: undefined },
  },
  regular: {
    [StepScreens.Basket]: { next: StepScreens.DonationDetails },
    [StepScreens.DonationDetails]: {
      next: (state) => {
        if(requiresPersonalInfo(state!)) {
         return  StepScreens.PersonalInfo;
        }

        if (state.selectedPaymentMethod === PaymentType.DirectDebit) {
          return StepScreens.DirectDebit;
        }

        return StepScreens.CreditCard
      },
      back: StepScreens.Basket,
    },
    [StepScreens.PersonalInfo]: { 
      next: state => {
        if (state.selectedPaymentMethod === PaymentType.Credit) {
          return StepScreens.CreditCard;
        }

        if (state.selectedPaymentMethod === PaymentType.DirectDebit) {
          return StepScreens.DirectDebit;
        }

        return StepScreens.PersonalInfo;
      },
      back: StepScreens.DonationDetails 
    },
    
    [StepScreens.CreditCard]: { next: StepScreens.ThankYou, back: StepScreens.PersonalInfo },
    [StepScreens.DirectDebit]: { next: StepScreens.ThankYou, back: StepScreens.PersonalInfo },
    [StepScreens.ThankYou]: { back: undefined },
  },
  scheduled: {
    [StepScreens.Basket]: { next: StepScreens.DonationDetails },
    [StepScreens.DonationDetails]: {
      next: (state) => requiresPersonalInfo(state!) ? StepScreens.PersonalInfo : StepScreens.ThankYou,
      back: StepScreens.Basket,
    },
    [StepScreens.PersonalInfo]: { 
      next: state => {
        if (state.selectedPaymentMethod === 'creditCard') {
          return StepScreens.CreditCard;
        }
        if (state.selectedPaymentMethod === 'directDebit') {
          return StepScreens.DirectDebit;
        }

        return StepScreens.PersonalInfo;
      },
      back: StepScreens.DonationDetails 
    },
    
    [StepScreens.CreditCard]: { next: StepScreens.ThankYou, back: StepScreens.PersonalInfo },
    [StepScreens.DirectDebit]: { next: StepScreens.ThankYou, back: StepScreens.PersonalInfo },
    [StepScreens.ThankYou]: { back: undefined },
  },
  oneOffAndRegular: {
    [StepScreens.Basket]: { next: StepScreens.DonationDetails },
    [StepScreens.DonationDetails]: {
      next: StepScreens.DonationSummary,
      back: StepScreens.Basket,
    },
    [StepScreens.PersonalInfo]: { 
      next: state => {
        if (state.selectedPaymentMethod === PaymentType.Credit) {
          return StepScreens.CreditCard;
        }

        if (state.selectedPaymentMethod === PaymentType.DirectDebit) {
          return StepScreens.DirectDebit;
        }
        
        if (state.selectedPaymentMethod === PaymentType.Instant) {
          return StepScreens.BankTransfer;
        }

        return StepScreens.ThankYou;
      },
      back: StepScreens.DonationSummary 
    },
    [StepScreens.DonationSummary]: { 
      next: (state) => {
        if (requiresPersonalInfo(state!)) {
          return StepScreens.PersonalInfo;
        }

        if (state.selectedPaymentMethod === PaymentType.Credit) {
          return StepScreens.CreditCard;
        }

        if (state.selectedPaymentMethod === PaymentType.DirectDebit) {
          return StepScreens.DirectDebit;
        }
        
        if (state.selectedPaymentMethod === PaymentType.Instant) {
          return StepScreens.BankTransfer;
        }

        if (isAppleOrGooglePay(state.selectedPaymentMethod)
            && !state.checkoutSession.account?.isComplete
          ) {
          return StepScreens.PersonalInfo;
        }

        if (isAppleOrGooglePay(state.selectedPaymentMethod)
          && state.checkoutSession.account?.isComplete
        ) {
          return StepScreens.DonationDetails;
        }
      
        return StepScreens.ThankYou;
      },
      back: StepScreens.DonationDetails
    },
    [StepScreens.CreditCard]: { 
      next: (state) => {
        if (state.checkoutSession.progress?.remainingStages?.length) {
          return StepScreens.DonationSummary;
        }

        return StepScreens.ThankYou
      },
      back: state => {
        if (requiresPersonalInfo(state!)) {
          return StepScreens.PersonalInfo;
        }

        return StepScreens.DonationSummary
      } 
    },
    [StepScreens.DirectDebit]: { 
      next: (state) => {
        if (state.checkoutSession.progress?.remainingStages?.length) {
          return StepScreens.DonationSummary;
        }

        return StepScreens.ThankYou
      }, 
      back: state => {
        if (requiresPersonalInfo(state!)) {
          return StepScreens.PersonalInfo;
        }

        return StepScreens.DonationSummary
      } 
    },
    [StepScreens.BankTransfer]: { 
      next: (state) => {
        if (state.checkoutSession.progress?.remainingStages?.length) {
          return StepScreens.DonationSummary;
        }

        return StepScreens.ThankYou
      }, 
      back: state => {
        if (requiresPersonalInfo(state!)) {
          return StepScreens.PersonalInfo;
        }

        return StepScreens.DonationSummary
      }
    },
    [StepScreens.ThankYou]: { back: undefined },
  },
};

export const navigationSchema: NavigationSchema = baseSchema;

export function getNextStep(state: CheckoutState): Step | undefined {
  const currentStep = state.currentStep as Step;
  const donationType = state.donationType;
  const rule = navigationSchema[donationType][currentStep];

  if (!rule) {
    throw new Error(`No navigation rule found for ${donationType} at step ${currentStep}`);
  }

  if (typeof rule.next === 'function') {
    return rule.next(state);
  }

  return rule.next;
}

export function getPreviousStep(state: CheckoutState): Step | undefined {
  const currentStep = state.currentStep as Step;
  const donationType = state.donationType;
  const rule = navigationSchema[donationType][currentStep];

  if (rule && rule.back) {
    if (typeof rule.back === 'function') {
      return rule.back(state);
    }
    return rule.back;
  }

  return undefined;
}