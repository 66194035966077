import { useSendOTP, useVerifyOTP } from '@/api/accounts';
import { K2RestService } from '@/api/common/clients/K2RestService';
import { OTPVerficationType, PersonalInfoState } from '../types';
import { AccountMatchCriteria, AccountType } from '@n3oltd/k2.accounts.sdk.public';

type UseOTPVerificationProps = {
  infoState: PersonalInfoState;
  updateInfoState: (newState: Partial<PersonalInfoState>) => void;
  getValues: (fieldName?: string | string[]) => any;
  trigger: (name?: string | string[]) => Promise<boolean>;
  findAddress: (req: AccountMatchCriteria) => Promise<void>;
  accountType: AccountType | undefined;
  orgName: string | undefined
};

export const useOTPVerification = ({
  infoState,
  updateInfoState,
  getValues,
  trigger,
  findAddress,
  accountType,
  orgName,
}: UseOTPVerificationProps) => {
  const { sendOTP } = useSendOTP();
  const { verifyOTP } = useVerifyOTP();

  const handleOTPComplete = async (code: string) => {
    const value = infoState.verificationFor === OTPVerficationType.Email ? getValues('email') : getValues('phone');
    const resp = await verifyOTP(code, value as string, infoState.verificationFor || 'email');

    if (!resp.error && resp.result?.success) {

      if (infoState.verificationFor === OTPVerficationType.Email) {
        updateInfoState({showOTP: false, otpVerification: {...infoState.otpVerification, email: {
          skip: false,
          otpReceived: true
        }}})
      }
      
      if (infoState.verificationFor === OTPVerficationType.Phone) {
        updateInfoState({showOTP: false, otpVerification: {...infoState.otpVerification, phone: {
          skip: false,
          otpReceived: true
        }}})
      }

      K2RestService.setToken(resp.result.token?.accessToken || '');
      
      const req: AccountMatchCriteria = { 
        email: infoState.verificationFor === OTPVerficationType.Email ? getValues('email') as string : undefined,
        telephone: infoState.verificationFor === OTPVerficationType.Phone ? getValues('phone') as string : undefined,
        accountType: accountType,
        organizationName: accountType === AccountType.Organization ? orgName : undefined
      }

      findAddress(req);
    }
    
    if (!resp.error && !resp.result?.success) {
      updateInfoState({ isWrongOTP: true });
    }
  };

  const handleEmailBlur = async () => {
    const {email: optEmail} = infoState.otpVerification;
    if (optEmail.otpReceived || optEmail.skip || infoState.showOTP) {
      return;
    }

    const value = getValues('email') as string;
    if (!value) {
      return;
    }

    const isValid = await trigger('email');
    updateInfoState({ verificationFor: OTPVerficationType.Email });

    if (isValid && !optEmail.otpReceived) {
      updateInfoState({ loadings: { fetchingOTP: true } });
      const email = getValues('email') as string;
      const res = await sendOTP(email, 'email').finally(() => {
        updateInfoState({ loadings: { fetchingOTP: false } });
      });
      
      if (!res.error && res.result) {
        updateInfoState({ showOTP: true });
      }
    }
  };

  const handlePhoneBlur = async () => {
    const {phone: optPhone} = infoState.otpVerification;
    if (optPhone.otpReceived || optPhone.skip || infoState.showOTP) {
      return;
    }

    const phone = getValues('phone') as string;
    if (!phone) {
      return;
    }

    const isValid = await trigger('phone');
    updateInfoState({ verificationFor: OTPVerficationType.Phone });
    
    if (isValid && !optPhone.otpReceived) {
      updateInfoState({ loadings: { fetchingOTP: true } });
      
      const res = await sendOTP(phone, 'phone').finally(() => {
        updateInfoState({ loadings: { fetchingOTP: false } });
      });
      if (!res.error && res.result) {
        updateInfoState({ showOTP: true });
      }
    }
  };

  return {
    handleOTPComplete,
    handleEmailBlur,
    handlePhoneBlur
  };
};