import { useCallback } from "react";
import { OtpChannelReq } from "@n3oltd/karakoram.elements.sdk.login";

import { _loginClient } from "@api/common/clients/K2RestClient";
import { IApiResponse } from "@api/common/clients/ApiResponseModel";
import K2RestService from "@api/common/clients/K2RestService";

export type OTPType = "email" | "phone";

export const useSendOTP = (): {
  sendOTP: (value: string, otpType: OTPType) => Promise<IApiResponse<boolean>>;
} => {

  const sendOTP = useCallback(async (value: string, otpType: OTPType) => {
    const req:OtpChannelReq = {
      email: otpType === "email" ? value : undefined, 
      phoneNumber: otpType === "phone" ? value : undefined,
    }

    const res = await K2RestService.toResponse(_loginClient!.trySendOtp(req));
    
    return res
  }, []);

  return {
    sendOTP
  }
}