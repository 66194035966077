import React from "react";
import { BooleanPaymentFlowRes, EligibleBanksApiRes, StringPaymentFlowRes } from "@n3oltd/karakoram.checkout.sdk.payit";
import { Button, PaymentOption } from "@n3oltd/n3o-ui-components";
import { CheckoutRes } from "@n3oltd/karakoram.checkout.sdk.checkout";
import { ArrowLeft, LucideInfo } from "lucide-react";

import { useTranslation } from "@/i18n";
import { useApi } from "@/api/common/hooks/useApi";

import { Drawer } from "./Drawer";
import { BankList } from "./payments/BankList";
import { LoadingOverlay } from "@/Loader";
import { _checkoutClient, _payitFlowsPaymentsClient } from "@/api/common/clients/K2RestClient";
import { PAYMENT_PROCESSORS } from "../consntants";
import Idempotency from "../utils/Idempotency";
import { CheckoutState, StepScreens } from "../types";
import { getNextStage } from "../utils/stageProgress";
import { PaymentError } from "./payments/PaymentError";

import './bankTransfer.css'

interface BankTransferPaymentProps {
  state: CheckoutState;
  updateState: (newState: Partial<CheckoutState>) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  isSheetOpen: boolean;
  setIsSheetOpen: (isOpen: boolean) => void;
}

let openTab: any = null;

export default function BankTransferPayment({
   goToPreviousStep,
   goToNextStep,
   updateState,
   state
}: BankTransferPaymentProps) {
  const [paymentError, setPaymentError] =  React.useState({show: false, error: ''});
  
  const { formatMessage } = useTranslation();


  const {execute, data, isLoading} =  useApi<EligibleBanksApiRes>();

  const payitPayment = state.checkoutProfile?.payments.paymentMethods.find(p => p.processorId === PAYMENT_PROCESSORS.Payit);

  const {poll, isLoading: isPolling} = useApi<BooleanPaymentFlowRes>({
    shouldStopPolling: (data: BooleanPaymentFlowRes) =>  data.error === true || !data.result,
    pollingInterval: 5000,
    onSuccess: async (data: BooleanPaymentFlowRes ) => {
    
      if (data.error) {
        setPaymentError({show: true, error: formatMessage("bankTransfer.paymentError")});
        if(openTab){
          openTab.close();  
        }
      }

      if (!data.result) {
        if(openTab){
          openTab.close();  
        }
       const resp = await _checkoutClient!.getById(state.checkoutSession.id!);
       updateState({checkoutSession: resp});
       const {isPaid, isDeclined, declinedReason, hasError, errorMessage} = resp.donation?.payment || {};

       if (isPaid) {
        getNextStage(resp.progress?.remainingStages, nextStage => {
          updateState({currentDonationFlow: nextStage});
        });

        goToNextStep();
        window.themeConfig?.onCheckoutStepComplete && window.themeConfig.onCheckoutStepComplete(state.currentDonationFlow, resp)
       }

       if(isDeclined){
        setPaymentError({show: true, error: declinedReason || ''})
       }
       
       if(hasError){
        setPaymentError({show: true, error: errorMessage || ''})
       }
      }
    },
    onError: (error: any) => setPaymentError({show: true, error: error.title})
  });

  const {execute: startPaymentSession, data: paymentFlow, isLoading: startingPaymentFlow} =  useApi<StringPaymentFlowRes>({
    onSuccess: (data: StringPaymentFlowRes) => {
      if (data.result) {
        getCheckoutSession(_checkoutClient!.getById(state.checkoutSession.id!))
      }
    }
  });

  const {execute: getCheckoutSession, isLoading: checkoutSessionLoading} =  useApi<CheckoutRes>({
    onSuccess: (data) => {
      updateState({checkoutSession: data});
      openTab = window.open(paymentFlow!.result, '_blank');
      poll(() => _payitFlowsPaymentsClient!.isProcessing(payitPayment!.id, data.id || '', 'donation', data.donation?.payment?.info?.id || ''), 'poll')
    }
  });

  React.useEffect(() => {
    execute(
    _payitFlowsPaymentsClient!.getEligibleBanks(payitPayment!.id)
    )
  }, [execute]);

  React.useEffect(() => {

    if (paymentError.show || !state.hasPayitHash) {
      return
    }

    const {donation} = state.checkoutSession;
    if (donation?.isComplete && donation.payment?.isPaid) {
      getNextStage(state.checkoutSession?.progress?.remainingStages, nextStage => {
        updateState({currentDonationFlow: nextStage});
      });

      goToNextStep();
      return;
    }

    if (donation?.payment?.hasError) {
      setPaymentError({show: donation?.payment?.hasError, error: donation.payment.errorMessage || ''})
    }

    if (donation?.payment?.isDeclined) {
      setPaymentError({show: donation?.payment?.isDeclined, error: donation.payment.declinedReason || ''})
    }

    if (donation?.payment?.isInProgress) {
      poll(() => _payitFlowsPaymentsClient!.isProcessing(payitPayment!.id, state.checkoutSession.id || '', 'donation', donation?.payment?.info?.id || ''), 'poll')
    }

  }, [setPaymentError, state, paymentError]);


  const onBankSelect =  (bankId: string | undefined) => {
    startPaymentSession(_payitFlowsPaymentsClient!.start(payitPayment!.id, state.checkoutSession.id || '', 'donation', {
      bankId: bankId,
      redirectUrl: payitPayment?.additionalData?.redirectUrl,
      idempotencyKey: Idempotency.createBankTransferKey(bankId),
      value: state.checkoutSession.donation?.total
    }))
  }

  const loadingLabel = (() => {

    if (startingPaymentFlow || checkoutSessionLoading) {
      return 'bankTransfer.creatingSession';
    }

    if (isPolling) {
      return 'bankTransfer.verifyingPayment'
    }

    return 'common.loading'
  })()

  const handleChangePaymentMethod = () => {
    updateState({
      selectedPaymentMethod: null,
      currentStep: StepScreens.DonationDetails,
    });
  };

  if (paymentError.show) {
    return <PaymentError error={paymentError.error} onPaymentMethodChange={handleChangePaymentMethod} onPaymentRetry={() =>  setPaymentError({show: false, error: ''})}/>
  }
 
  return (
    <>
      <LoadingOverlay
        isLoading={
          isLoading ||
          startingPaymentFlow ||
          isPolling ||
          checkoutSessionLoading 
        }
        loadingText={formatMessage(loadingLabel)}
        blur={false}
      />
      <Drawer.ScrollableContent>
        <BankList
          banks={
            data?.result!.banks?.filter((b) => !b.temporarilyUnavailable) || []
          }
          onBankSelect={onBankSelect}
        />
      </Drawer.ScrollableContent>
      <Drawer.Footer>
        <p className="flex items-center text-sm font-medium text-gray-600">
          <LucideInfo className="h-4 w-4 mr-2" />{" "}
          {formatMessage("bankTransfer.note")}
        </p>
        <PaymentOption.Divider />
        <p
          dangerouslySetInnerHTML={{
            __html: data?.result?.paymentTermsHtml || "",
          }}
          className="text-sm font-medium text-gray-600 custom-p a"
        ></p>
        <Button
          variant="outline"
          className="w-full"
          onClick={handleChangePaymentMethod}
        >
          {formatMessage("common.button.changePaymentMethod")}
        </Button>
        <div className="flex gap-2">
          <Button
            type="button"
            variant="outline"
            size="lg"
            className="flex-1"
            onClick={goToPreviousStep}
          >
            <ArrowLeft className="h-4 w-4 ml-2" />
            {formatMessage("common.button.back")}
          </Button>
        </div>
      </Drawer.Footer>
    </>
  );
}