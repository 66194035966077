export const CHECKOUT_SCOPES = {
  'donation': 'donation',
  'regularGiving': 'regular',
  'scheduledGiving': 'scheduled'
}

export const NMI_SCRIPTS = {
  GATEWAY: 'https://secure.nmi.com/js/v1/Gateway.js',
  COLLECT_JS: 'https://secure.safewebservices.com/token/Collect.js'
}

export const PAYMENT_PROCESSORS = {
  Payit: 'payit',
  NMI: 'networkMerchants',
  DirectDebitUK: 'directDebitUK'
}
