import { CheckoutElementWrapper } from "../../wrappers/CheckoutElementWrapper";
import r2wc from "@r2wc/react-to-web-component";

declare global {
  interface Window {
    __styles: Record<string, CSSStyleSheet>;
  }
}

export const r2wcStyled = <Props extends object>(
  Component: Parameters<typeof r2wc<Props>>[0],
  options?: Parameters<typeof r2wc<Props>>[1]
) => {
  const WebComponent = r2wc<Props>(Component, { shadow: 'open', ...options });

  class WebComponentWithStyle extends WebComponent {
    connectedCallback() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      super.connectedCallback();
      // @ts-ignore
      const shadowRoot = this.container as ShadowRoot;
      shadowRoot.adoptedStyleSheets = Object.values(window.__styles || {});
      //document.adoptedStyleSheets = Object.values(window.__styles || {});
      window.addEventListener('vite:css-injected-by-js', () => {
        //document.adoptedStyleSheets = Object.values(window.__styles || {});
        shadowRoot.adoptedStyleSheets = Object.values(window.__styles || {});
      });
    }
  }

  return WebComponentWithStyle;
};

const CheckoutComponent = r2wcStyled(CheckoutElementWrapper, { 
  shadow: "open", 
  props: {
    subscriptionId: "string",
    checkoutProfileId: "string",
    open: "boolean",
    legacy: "boolean",
    onClose: 'function',
    onError: 'function'
  }
}) as any;


customElements.define("n3o-checkout", CheckoutComponent);