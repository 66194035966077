import { CheckoutState } from '../types';

type UsePreferencesProps = {
  state: CheckoutState;
  updateState: (newState: Partial<CheckoutState>) => void;
};

export const usePreferences = ({ state, updateState }: UsePreferencesProps) => {
  const updatePrefs = (value: { id: string; isChecked: boolean }) => {
    updateState({
      checkoutInfo: {
        ...state.checkoutInfo,
        prefs: {
          ...state.checkoutInfo.prefs,
          [value.id]: value.isChecked
        }
      }
    });
  };

  return {
    updatePrefs
  };
};