import { Environment } from "../contexts/EnvironmentProvider";

export type ProfileConfig = {
  host: string;
};

export type ProfileConfigs = {
  [key in Environment]: ProfileConfig;
};

export const profileConfig: ProfileConfigs = {
  prod: {
    host: "https://static.n3o.cloud"
  },
  qa: {
    host: "https://static-beta.n3o.cloud"
  },
  development: {
    host: "https://static-beta.n3o.cloud"
   
  },
};
