import {IConfig} from '@n3oltd/karakoram.checkout.sdk.checkout'
import {StatusCodes} from 'http-status-codes'
import ApiResponseModel, {
  IApiResponse,
  K2StatusCodes,
  ProblemDetails,
  ServerError,
} from "./ApiResponseModel";
import AppManager from "./AppManager";

export class K2RestService {
  static token: string;
  static subscriptionId: string;
  static errorHandler: any;

  public static setToken(token: string) {
    this.token = token;
  }

  public static getToken() {
    return this.token;
  }

  public static setSubscriptionId(id: string) {
    this.subscriptionId = id;
  }

  public static getSubscriptionId() {
    return this.subscriptionId || "";
  }

  public static setErrorHandler(handler: any) {
    return this.errorHandler = handler;;
  }
  
  private successResponseClient = <T>(res: T) => {
    return new ApiResponseModel(res);
  };

  public readonly defaultClientConfiguration: IConfig = {
    getAuthorization: () => {
      if(K2RestService.getToken()){
        return `Bearer ${K2RestService.getToken()}`;
      }
      
      return '';
    },
    getN3OSubscription: () => K2RestService.getSubscriptionId(),
    getN3OAppId: () => AppManager.getAppId(),
    getN3ORouteKey: () => "",
    getN3OIgnoreValidationWarnings: () => "false",
    getN3OClockAdjustment: () => "0",
    getN3OServiceCache: () => "false",
    getN3OAccountActivityScopeId: () => ''
  };



  private errorResponseClient = (restError: ProblemDetails): IApiResponse<any> => {
    // 500 Errors are not returned by the clients already parsed into ProblemDetails, so attempt to parse them here
    if (restError.response) {
      try {
        if (restError.status && restError.status >= 500) {
          K2RestService.errorHandler(restError);
        }
        restError = JSON.parse(restError.response);
      } catch (e) {
        console.log(e);
      }
    }

    const serverError = new ServerError(restError, restError?.status);
    return new ApiResponseModel<any>(null, serverError);
  };


  toResponse = <T>(p: Promise<T>): Promise<IApiResponse<T>> => {
    return p
      .then((r: T) => {
        const isUnauthorized = 
          (r as any)?._response?.status === K2StatusCodes.unAuthorized;

        if (isUnauthorized)
          return Promise.reject({
            ...r,
            statusCode: K2StatusCodes.unAuthorized,
          });

        return this.successResponseClient(r);
      })
      ?.catch(this.errorResponseClient);
  };
}

export default new K2RestService();
