type OnTabOpen = (hasHash: boolean) => void;

export class TabDetector {
    private tabId: string;
    private isSupported: boolean;
    private bc?: BroadcastChannel;

    constructor(onTabOpen: OnTabOpen, hasHash: boolean) {
        this.tabId = Math.random().toString();
        this.isSupported = 'BroadcastChannel' in window;

        if (this.isSupported) {
            this.initBroadcastChannel(onTabOpen, hasHash);
        } else {
            this.initLocalStorage(onTabOpen, hasHash);
        }
    }

    private initBroadcastChannel(onTabOpen: OnTabOpen, hasHash: boolean): void {
        this.bc = new BroadcastChannel('website_channel');
        
        this.bc.postMessage({
            name: 'tab_opened',
            tabId: this.tabId,
            hasHash: hasHash,
            url: window.location.href
        });

        this.bc.onmessage = (event: MessageEvent): void => {
            const data = event.data;
            
            if (data.name === 'tab_opened' && data.tabId !== this.tabId) {
                onTabOpen(data.hasHash);
            }
        };
    }

    private initLocalStorage(onTabOpen: OnTabOpen, hasHash: boolean): void {
        const updateStorage = () => {
            const data = {
                tabId: this.tabId,
                hasHash: hasHash,
                url: window.location.href,
                timestamp: Date.now()
            };
            localStorage.setItem('currentTab', JSON.stringify(data));
        };

        setInterval(updateStorage, 1000);
        updateStorage();

        window.addEventListener('storage', (e: StorageEvent): void => {
            if (e.key === 'currentTab' && e.newValue) {
                try {
                    const data = JSON.parse(e.newValue);
                    if (data.tabId !== this.tabId) {
                        onTabOpen(data.hasHash);
                    }
                } catch (error) {
                    console.error('Error parsing storage data:', error);
                }
            }
        });
    }

    public destroy(): void {
        if (this.bc) {
            this.bc.close();
        }
    }
}