export const loadGoogleMapsScript = (apiKey: string, onSuccess: () => void, onError: () => void) => {
  if (!apiKey) {
    return
  }

  if (document.getElementById('google-maps') && 'google' in window) {
    onSuccess();
    return;
  };

  const script = document.createElement('script');
  script.id = 'google-maps';
  script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
  script.onload = onSuccess;
  script.onerror = onError;
  document.body.appendChild(script);
};

export const initializeGooglePlaces = (input: HTMLInputElement, callback: (address: FormattedAddress) => void) => {
  const autocomplete = new google.maps.places.Autocomplete(input, {
    types: ['geocode' ],
  });

  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace();
    if (place.address_components) {
      const address = formatAddressForRegions(place.address_components);
      callback(address)
    }
  });
}

export const getAddressComponent = (place: google.maps.places.PlaceResult, type: string) => {
  const component = place.address_components?.find((comp) => comp.types.includes(type));
  return component ? component.long_name : '';
};

type AddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};

type FormattedAddress = {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  postalCode: string;
  country: string;
  stateOrProvince?: string;
  county?: string;
};

export const formatAddressForRegions = (components: AddressComponent[]): FormattedAddress => {
  const getComponent = (type: string, key: 'long_name' | 'short_name' = 'long_name') =>
    components.find((comp) => comp.types.includes(type))?.[key] || '';

  const streetNumber = getComponent('street_number');
  const route = getComponent('route');
  const premise = getComponent('premise') || getComponent('subpremise'); 
  const locality = getComponent('locality') || getComponent('postal_town');
  const subLocality = getComponent('sublocality_level_2');
  const postalCode = getComponent('postal_code');
  const country = getComponent('country', 'short_name');
  const county = getComponent('administrative_area_level_2');
  const stateOrProvince = getComponent('administrative_area_level_1'); 

  const addressLine1 = [premise, streetNumber, route].filter(Boolean).join(' ');
  const addressLine2 = subLocality;

  return {
    addressLine1,
    addressLine2,
    city: locality,
    postalCode,
    country,
    stateOrProvince: stateOrProvince || county,
  };
};