import { ArrowRight } from "lucide-react";
import { Button } from "@n3oltd/n3o-ui-components";

import { Drawer } from "../Drawer";
import { useTranslation } from "@/i18n";
import { WarningAlert } from "@/common/components/WarningAlert";

type Props = {
  onPaymentMethodChange: () =>  void,
  onPaymentRetry: () =>  void,
  error: string
};


export function PaymentError({ onPaymentRetry, onPaymentMethodChange, error }: Props) {
  const { formatMessage,  } = useTranslation();
 
  return (
    <>
        <h2 className="text-center text-xl font-semibold pt-8">{formatMessage("payment.failed")}</h2>
        <div className="flex-1 flex items-center justify-center h-full">
          <WarningAlert message={error} />
        </div>
      <Drawer.Footer>
        <div className="space-y-3">
          <Button
            variant="outline"
            className="w-full"
            onClick={onPaymentMethodChange}
          >
            {formatMessage("common.button.changePaymentMethod")}
          </Button>

          <div className="flex gap-2">
            <Button size="lg" className="w-full " onClick={onPaymentRetry}>
              {formatMessage("payment.retry")}
              <ArrowRight className="absolute right-8 h-4 w-4" />
            </Button>
          </div>
        </div>
      </Drawer.Footer>
    </>
  );
}